import Vue from 'vue'
import 'amfe-flexible';

import App from './App.vue';
import { router } from './router';
Vue.config.productionTip = false;
// import VueAwesomeSwiper from 'vue-awesome-swiper';
// import 'swiper/css/swiper.css';
// Vue.use(VueAwesomeSwiper);
import { 
    Button,
    DatetimePicker,
    Calendar,
    Form,
    Field,
    Tabbar,
    TabbarItem,
    Swipe,
    SwipeItem,
    Checkbox,
    CheckboxGroup,
    Switch,
    Popup,
    Tag,
    RadioGroup,
    Radio,
    Area,
    Toast,
    Empty,
    Uploader,
    Picker,
    Icon,
    ImagePreview,
    Cell,
    CellGroup,
    ActionSheet,
    Tab,
    Tabs,
    PullRefresh,
    List,
    Overlay,
    NoticeBar,
    Loading,
    Stepper,
    NavBar
    
} from 'vant';

Vue.use(Loading);
Vue.use(Button);
Vue.use(DatetimePicker);
Vue.use(Calendar);
Vue.use(Form);
Vue.use(Field);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Switch);
Vue.use(Popup);
Vue.use(NavBar);
Vue.use(Tag);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Area);
Vue.use(Toast);
Vue.use(Empty);
Vue.use(Uploader);
Vue.use(Picker);
Vue.use(Icon);
Vue.use(ImagePreview);

Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(ActionSheet);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(PullRefresh);
Vue.use(List);
Vue.use(Overlay);
Vue.use(NoticeBar);
Vue.use(Stepper);

import VueAwesomeSwiper from 'vue-awesome-swiper';
import "swiper/css/swiper.min.css";
Vue.use(VueAwesomeSwiper);
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
