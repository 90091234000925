<template>
    <div class="addFamily">
        <div class="form_box">
            <div class="title">
                <div :class="publicType == '3' ? 'tit-con-yishu' : 'tit-con'">
                    <i></i>
                    <span>家人信息<span style="margin-left:15px;font-size: 10px;">直系亲属</span></span>
                </div>
            </div>
            <van-form @submit="onSubmit" ref="submitForm">
                <van-cell-group inset>
                    <van-field
                        v-model="personName"
                        name="personName"
                        label="姓名"
                        required
                        placeholder="请输入姓名"
                        :rules="[{ required: true, message: '请输入姓名' }]"
                    />
                    <van-field
                        v-model="credentialsNumber"
                        name="credentialsNumber"
                        label="证件号码"
                        required
                        placeholder="请输入证件号码"
                        :rules="[{ required: true, pattern: idCardPattern, message: '请输入正确的身份证号' }]"
                    />
                    <van-field
                        name="relationType"
                        label="与本人关系"
                        required
                        :rules="[{ validator: relativeShip, message: '请选择' }]"
                    >
                        <template #input>
                            <van-radio-group v-model="relationType" direction="horizontal">
                            <van-radio v-if="isPlus == 'true'" :class="publicType == '3' ? 'radio-yishu' : ''" :name="4">父母</van-radio>
                            <van-radio :class="publicType == '3' ? 'radio-yishu' : ''" :name="2">配偶</van-radio>
                            <van-radio :class="publicType == '3' ? 'radio-yishu' : ''" :name="3">子女</van-radio>
                            </van-radio-group>
                        </template>
                    </van-field>
                </van-cell-group>
            </van-form>
        </div>
        <div class="footer">
            <van-button v-if="publicType == 3" style="background-color: #12cd9f;border: 0.02667rem solid #12cd9f;" round block type="info" @click="submit">确认添加</van-button>
            <van-button v-if="publicType != 3" round block type="info" @click="submit">确认添加</van-button>
        </div>
    </div>
</template>
<script>
import { Toast } from 'vant';
import { onAddPersonFamily } from '@/api';

export default {
    data() {
        return {
            personName: '',
            credentialsNumber: '',
            idCardPattern: /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}([0-9]|x|X)$/,
            relationType: null,
            personPrivilegeId: null,
            loading: false,
            publicType : '',
            isPlus: false,
        }
    },
    created() {
        this.personPrivilegeId = this.$route.query.personPrivilegeId;
        this.isPlus = this.$route.query.isPlus
        this.publicType = localStorage.getItem('publicType');
    },
    methods: {
        submit() {
            this.$refs.submitForm.submit();
        },
        async onSubmit(values) {
            if(this.loading) {
                return ;
            }
            this.loading = true;
            console.log('submit', values);
            try {
            const res = await onAddPersonFamily({
                personPrivilegeId: this.personPrivilegeId,
                personName: this.personName,
                credentialsNumber: this.credentialsNumber,
                relationType: this.relationType
            });
            if (res.code == 200) {
                Toast('添加成功!');
                this.loading = false;
                setTimeout(() => {
                    this.$router.go(-1);
                }, 2000);
            } else {
                Toast(res.msg);
                this.loading = false;
            }
          } catch(err) {
            this.loading = false;
            console.log('err', err);
            Toast(err);
          }
        },
        relativeShip() {
            return this.relationType !== null;
        }
    }
}
</script>
<style scoped lang="less">
    .addFamily {
        width: 100%;
        padding: 16px;
        .form_box {
            padding-top: 16px;
            border-radius: 12px;
            background: #fff;
            box-shadow: 0 2px 8px 0 rgb(0 0 0 / 4%);
            .title {
                font-size: 16px;
                font-weight: 500;
                line-height: 22px;
                display: flex;
                .tit-con {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    i {
                        display: inline-block;
                        vertical-align: middle;
                        margin-right: 12px;
                        width: 4px;
                        height: 18px;
                        background: #005dcc;
                        border-radius: 2px;
                    }
                    span {
                        vertical-align: middle;
                    }
                }
                .tit-con-yishu {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    i {
                        display: inline-block;
                        vertical-align: middle;
                        margin-right: 12px;
                        width: 4px;
                        height: 18px;
                        background: #12cd9f;
                        border-radius: 2px;
                    }
                    span {
                        vertical-align: middle;
                    }
                }
            }
        }
        .footer {
            width: 100%;
            height: 80px;
            border-top: 1px solid #f4f4f4;
            background: #fff;
            position: fixed;
            bottom: 0;
            left: 0;
            z-index: 100;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 16px;
        }
        .van-form {
            // padding: 0 16px;
            .van-field {
                padding-top: 16px;
                padding-bottom: 16px;
                .van-field__label {
                    width: 88px;
                    margin-right: 0;
                    color: #666;
                    white-space: pre-line;
                }
                /deep/ .van-field__error-message {
                    line-height: 12px;
                    position: absolute;
                    bottom: -11px;
                    left: 0;
                }
            }
        }
    }
    ::v-deep .radio-yishu .van-radio__icon--checked .van-icon{
        background-color: #12cd9f;
        border-color: #12cd9f;
    }
</style>