<template>
    <div class="login">
        <div class="banner">
            <img src="@/assets/content1_banner.jpg" />
        </div>
        <div class="text-dialog flex flex-column">
                <!-- <h2>{{content.title}}</h2> -->
                <div class="text-content flex-1" v-html="content.content"></div>
        </div>
    </div>
</template>

<script>
import { contents } from './util';
import { Toast } from 'vant';
import { weixinInit } from '@/utils/wechatUtil.js';

export default {
    data() {
        return {
            mobile: '',
            sms: '',
            checked: false,
            showFlag: false,
            values: null,
            content: '',
            time: 60,
            btnStatus: true,
            btnText: '获取验证码',
            publicType: null,
            weixinShare: {
                title: '特药项目介绍',
                desc: '国药集团和旗下健康平台联合打造',
                url: '',
                imgUrl: 'https://more-health.oss-cn-beijing.aliyuncs.com/image/fc3e90ed-f0b0-4755-aab0-e860ce0bd591.jpg',
                publicType: ''
            }
        }
    },
    created() {
        this.content = contents[0];
        this.publicType = localStorage.getItem('publicType');
        this.weixinShare.url = window.location.href.split('#')[0];
        this.weixinShare.publicType = this.publicType;
        this.wxConfigInit();
        
    },
    methods: {
        async wxConfigInit() {
            await weixinInit(this.weixinShare);
        },
        onShow(type) {
            this.showFlag = true;
            this.content = contents[type];
        }
    }
}
</script>
<style scoped lang="less">
.login .login-form {
    margin-top: 30px;
    padding: 0 40px;
}
.login .banner {
    width: 100%;
    margin: 0;
    img {
        width: 100%;
    }
}
.login .logo img {
    width: 100%;
}
.login .title {
    font-size: 14px;
    margin-bottom: 15px;
    font-weight: 500;
}
.van-cell {
    position: relative;
    border: 0.5px solid #e4e4e4;
    border-radius: 30px;
    margin-bottom: 20px;
    line-height: 25px;
}
::v-deep .van-field__left-icon {
    display: flex;
    align-items: center;
}
.safe {
    width: 18px;
}
.valid-text {
    color: rgb(0, 93, 204);
    font-size: 14px;
}
::v-deep .van-field__control {
    color: #666 !important;
}
::v-deep .van-field__error-message {
    position: absolute;
    line-height: 12px;
    bottom: -8px;
}
.login-btn {
    margin: 30px 0 20px;
}
.agreement {
    margin-top: 20px;
    align-items: flex-start;
    font-size: 12px;
    color: #1d1d1d;
}
.agreement .text-link {
    color:rgb(0, 93, 204);
}
.text-dialog {
    height: 100%;
    color: #333;
    h2{
        font-size: 16px;
        color: #333;
        margin: 0 auto;
        padding: 20px 0;
        text-align: center;
    }
    .text-content {
        padding: 0 10px 30px;
        font-size: 14px;
        line-height: 22px;
        overflow: auto;
        ::v-deep p {
            text-indent: 2em;
            line-height: 24px;
            color: #333;
            font-weight: 400;
            font-size: 14px;
            letter-spacing: 1px;

        }
        ::v-deep img {
            display: block;
            width: 50%;
            font-size: 0;
            margin: 10px auto;
        }
    }
}
::v-deep table {
    width: 100% !important;
}
</style>