/* eslint-disable */
import axios from "axios";

class RequestManager {
    constructor() {
        // 创建axios实例并配置
        this.axiosInstance = axios.create({
            //baseURL: 'https://2o1841f010.oicp.vip',
            baseURL: 'https://www.healthmore.net/api',
            //baseURL: 'http://www-test.healthmore.net/api',
            //baseURL: 'http://localhost:8091',
            timeout: 30 * 1000
        });

        // 配置请求和响应的拦截器
        this._configInterceptors();

        this.defaultErrorHandler = function () {

        }
    }

    static _instance = null;

    static getInstance() {
        if (null == RequestManager._instance) {
            RequestManager._instance = new RequestManager();
        }

        return RequestManager._instance;
    }

    _configInterceptors() {
        this.axiosInstance.interceptors.request.use(
            (config) => {
                console.log('config', config);
                // if (config.url == '/system/uploadImg') {
                //     // -- 请求头统一参数
                //     config.headers["Content-Type"] = "application/x-www-form-urlencoded; charset=UTF-8";
                // } else {
                //     // -- 请求头统一参数
                    config.headers["Content-Type"] = "application/json; charset=UTF-8";
                // }
                
                console.log('config2', config);
                let token = localStorage.getItem("token");
                if (token) {
                    config.headers["Authorization"] = `Bearer ${token}`;
                }
                return config;
            },
            (error) => {
                // 对请求错误处理的回调
                return Promise.reject(error);
            }
        );

        this.axiosInstance.interceptors.response.use(
            (response) => {
                const res = response.data;
                // token 失效
                if (res.code === "1001006") {
                    return;
                }
                if (res.code == '401') {
                    // localStorage.clear();
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                    // 
                    return;
                }
                return response;
            },
            (error) => {
                return Promise.reject(error);
            }
        );
    }

    // 请求实例
    request(options) {
        options = Object.assign({}, options);
        let params = options.params || {};
        let errorHandler = params.errorHandler || this.defaultErrorHandler;
        delete params.errorHandler;
        console.log('options', options)
        return this.axiosInstance(options)
            .then(function (res) {
                let result = res.data;
                // if (result.code !== "0") {
                //     let wrapError = {
                //         errorType: 1, // 1是业务错误
                //         error: result
                //     }
                //     // errorHandler(wrapError);

                //     return Promise.reject(wrapError);

                // } else {
                //     return result;
                // }
                return result;
            })
            .catch(function (error) {
                // let wrapError = {
                //     errorType: 0, // 0是网络错误
                //     error: error
                // }
                // // 如果是业务错误已经在之前包裹过，则直接使用error
                // if (error.errorType === 1) {
                //     wrapError = error;
                // }

                // errorHandler(wrapError)
                // error
                return Promise.reject(error);
            });
    }

    // 设置默认错误处理器
    setDefaultErrorHandler(defaultErrorHandler) {
        this.defaultErrorHandler = defaultErrorHandler;
    }
}

// 导出构造函数
export default new RequestManager();
