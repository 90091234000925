<template>
<div class="appointment">
    <div v-if="info">
        <div class="box margin-bottom-sm">
            <h4 class="box-title">
                <div class="box-title-con">
                    <i style="background: rgb(0, 93, 204);"></i>
                    <span>预约信息</span>
                </div>
            </h4>
            <div class="box-content">
                <div class="box-content_status">{{ info.applyState | statusFilter }}</div>
                <div class="box-content_info">
                    <p class="title">{{ info.privilegeName }}</p>
                    <dl>
                        <dt>
                            <p>预约人；{{ info.personName }}</p>
                            <p>手机号：{{ info.phone }}</p>
                            <p>预约时间：{{ info.applyDate }}</p>
                            <p v-if="info.applyHospitalName != null">预约医院：{{ info.applyHospitalName }}</p>
                            <p v-if="info.applyHospitalAddress != null">医院地址：{{ info.applyHospitalAddress }}</p>
                        </dt>
                        <dd>
                            <img :src="info.privilegeImgUrl" />
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
        <div class="box margin-bottom-sm">
            <h4 class="box-title">
                <div class="box-title-con">
                    <i style="background: rgb(0, 93, 204);"></i>
                    <span>其它材料</span>
                </div>
            </h4>
            <div class="box-documents">
                <div class="box-documents_idcard" v-if="info.credentialsFrontUrl != null">
                    <p>身份证件</p>
                    <div>
                        <img :src="info.credentialsFrontUrl" alt="">
                        <img :src="info.credentialsBackUrl" alt="">
                    </div>
                </div>
                <div class="box-documents_idcard" v-if="info.caseList.length > 0">
                    <p>既往门/急诊及住院病历</p>
                    <div>
                        <img v-for="(item, index) in info.caseList" :key="index" :src="item" alt="">
                    </div>
                </div>
                <div class="box-documents_idcard" v-if="info.diagnosticReport.length > 0">
                    <p>诊断报告</p>
                    <div>
                        <img v-for="(item, index) in info.diagnosticReport" :key="index" :src="item" alt="">
                    </div>
                </div>
                <div class="box-documents_idcard" v-if="info.otherList.length > 0">
                    <p>其他</p>
                    <div>
                        <img v-for="(item, index) in info.otherList" :key="index" :src="item" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="appointment-footer fixed" v-if="info.applyState == 1">
            <div class="btn-options">
                <van-button type="info" plain round block @click="onHandleCancel">取消预约</van-button>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import { onFetchDetailByApply, onCancleApply } from '@/api';
export default {
    data() {
        return {
            info: null
        }
    },
    filters: {
        statusFilter(status) {
            return status == 1 ? '提交预约' : status == 2 ? '预约取消' : status == 3 ? '预约完成' : '履约完成';
        }
    },
    created() {
        this.id = this.$route.query.id;
        this.init();

    },
    methods: {
        async init() {
            try {
                const res = await onFetchDetailByApply({
                    applyId: this.id
                });
                console.log('res', res);
                if (res.code == 200) {
                    this.info = res.data;
                }
            } catch(err) {
                console.log('err', err);
            }
        },
        // 取消预约
        async onHandleCancel() {
            try {
                const res = await onCancleApply({
                    applyId: this.id
                });
                if (res.code == 200) {
                    this.$toast('取消成功');
                    this.init();
                }
            } catch(err) {
                console.log('err', err);
            }
        }
    }
}
</script>
<style lang="less">
.appointment {
    padding: 10px 10px 60px;
    .box {
        &-content {
            padding: 10px 10px 0 10px;
            &_status {
                color: #32b7b9;
                background: #f5f5f5;
                padding: 10px 20px;
                border-radius: 10px;
                font-size: 14px;
            }
            &_info {
                padding-top: 10px;
                p {
                    margin-bottom: 10px;
                    font-size: 12px;
                    color: #666;
                    &.title {
                        font-size: 14px;
                        font-weight: 800;
                        color: #333;
                    }
                }
                dl {
                    display: flex;
                    justify-content: space-between;
                    dt {
                        p {
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                    dd {
                        padding-left: 10px;
                        img {
                            width: 60px;
                            height: 60px;
                            border-radius: 5px;
                            background: #000;
                        }
                    }
                }
            }
        }
        &-documents {
            padding: 0 10px;
            &_idcard {
                p {
                    font-size: 14px;
                    margin: 20px 0 10px;
                }
                img {
                    width: 80px;
                    height: 80px;
                    margin-right: 10px;
                    border-radius: 5px;
                }
            }
        }
    }
    &-footer {
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: #fff;
        z-index: 9;
        font-size: 14px;
        .btn-options {
            padding: 10px 20px;
            border-top: 1px solid #f4f4f4;
        }
    }
}
</style>