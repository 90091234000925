<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {
    }
  },
  // created() {

  //   const query = this.$route.query;
  //   this.code = query.code;
  //   // this.fullPath = query.url;
  //   // console.log('this.fulPath', this.fullPath)
  //   // this.fullPath = this.fullPath.replace('/middle?url=', '');
  //   // console.log('this.fulPath', this.fullPath)
  //   // if (this.code) {
  //   //   localStorage.setItem('code', query.code);
  //   //   this.onLogin();
  //   // } else {

  //   // }


  //   let wechat = this.isWehat();
  //   let token = localStorage.getItem('token');
  //   let fullPath = this.$route.fullPath;
  //   let code = localStorage.getItem('code');
  //   // 
  //   if (code) {
  //     this.code = code;
  //   }
  //   if (wechat) {
  //     console.log(1);
  //     if (!this.code) {
  //       console.log(2);
  //       // window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxa8795af1a3a9ca7b&redirect_uri=http://127.0.0.1:8081/?url=${encodeURI(fullPath)}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
  //       window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxa8795af1a3a9ca7b&redirect_uri=https://www.healthmore.net?url=${encodeURI(fullPath)}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
  //     } else {
  //       if (!token) {
  //         console.log(3);
  //         this.fullPath = query.url || this.$route.fullPath;
  //         localStorage.setItem('code', this.code);
  //         this.onLogin();
  //       }
  //     }
  //   } else {
  //     console.log(9);
  //     if (!token) {
  //         console.log(10);
  //         this.fullPath = this.$route.fullPath;
  //         localStorage.setItem('redirectUrl', this.fullPath);
  //         if (this.fullPath.search(/\/rights\?/) > -1){
  //           console.log(11);
  //             // this.$router.push(this.fullPath);
  //         } else {
  //           console.log(12);
  //           // console.log('this.fullPath', this.fullPath)
  //             // 1004 需要注册
  //             this.$router.push('/login');
  //         }
  //     }
  //   }
  // },
  methods: {
    isWehat() {
      let ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        return true;
      }
      return false;
    },
    async onLogin() {
      console.log(4);
      try {
          const res = await onWechatLogin({
              code: this.code
          });
          if (res.code == 200) {
              console.log(5);
              localStorage.setItem('token', res.data.token);
              this.fullPath = this.fullPath.replace('/personal?url=', '');
              this.$router.push(this.fullPath);
          } else if (res.code == 1004) {
              console.log(6);
              // 激活页,无需登陆
              if (this.fullPath.search(/\/rights\?/) > -1){
                console.log(7);
                  // this.$router.push(this.fullPath);
              } else {
                  console.log(8);
                  // 1004 需要注册
                  this.$router.push('/login');
              }
          } 

      } catch(err) {
          console.log('err', err);
      }
    }
  }
}
</script>
<style lang="less">
body, html {
  font-size: 16px;
  background-color: #f8f8f8;
  -webkit-font-smoothing: antialiased;
  background: #fcfcfc;
}
* {margin: 0;padding: 0;box-sizing: border-box;}
#app {
  font-family: Arial,Microsoft Yahei,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #666;
  box-sizing: border-box;
}
.flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}
.flex-column-center {
  align-items: center;
}
.flex-between{
  justify-content: space-between;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.text-left {
  text-align: left;
}
.padding-left-sm {
  padding-left: 10px;
}
.padding-left {
  padding-left: 20px;
}
.padding-lr-sm {
  padding: 0 10px;
}
.padding-lr-large {
  padding: 0 20px;
}
.padding-sm {
  padding: 10px;
}
.padding-right-sm {
  padding-right: 10px;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.margin-top {
  margin-top: 30px;
}
.margin-top-sm {
  margin-top: 10px;
}
.margin-bottom-sm {
  margin-bottom: 10px;
}
.fixed {
  position: fixed;
}

// .reserve_box {
//     background:#fff;
//     border-radius: 12px;
//     box-shadow:0 2px 8px 0 rgba(0,0,0,.04);
//     padding: 16px 0 80px;
//     .title{
//         font-size: 18px;
//         font-weight:500;
//         line-height:18px;
//         display:flex;
//         .tit-con{
//             flex:1;
//             display:flex;
//             align-items:center;
//             i {
//                 display:inline-block;
//                 vertical-align:middle;
//                 margin-right:10px;
//                 width:4px;
//                 height:20px;
//                 background:#005dcc;
//                 border-radius:2px;
//             }
//             span{
//                 vertical-align:middle;
//                 line-height: 14px;
//             }
//         }
//     }
// }

// 标准盒子模板
.box {
  background:#fff;
  border-radius: 12px;
  box-shadow:0 2px 8px 0 rgba(0,0,0,.04);
  padding: 16px 0;
  &-title {
    font-size: 18px;
    font-weight:500;
    line-height:18px;
    display:flex;
    &-con{
      flex:1;
      display:flex;
      align-items:center;
      i {
        display:inline-block;
        vertical-align:middle;
        margin-right:10px;
        width:4px;
        height:20px;
        background:#005dcc;
        border-radius:2px;
      }
      span{
        vertical-align:middle;
        line-height: 14px;
        color: #000;
      }
    }
  }
}
.van-uploader__upload {
  background: rgba(0,93,204,.05);
  border: 1px dashed #005dcc;
  border-radius: 0.08rem;
  .van-uploader__upload-icon {
    color: #005dcc;
  }
  .van-uploader__upload-text {
    margin-top: 0;
    color: #005dcc;
  }
}
.van-field--error .van-field__control, .van-field--error .van-field__control::placeholder {
  color: #999;
}
</style>