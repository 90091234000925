<template>
<div></div>
</template>
<script>
export default {
    data() {
        return {}
    },
    created() {
        // let ua = 'weixin';
        // if (ua) {
        //     window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxa8795af1a3a9ca7b&redirect_uri=http://127.0.0.1:8081/middle&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
        // } else {
        //     let token = localStorage.getItem('token');
        //     if (token) {
        //         this.$router.push('/rightDetail');
        //     }
        // }
    }
}
</script>