<template>
<div>
</div>
</template>
<script>
import { onWechatLogin } from '@/api';
export default {
    data() {
        return {
            code: '',
            fullPath: ''
        }
    },
    created() {
        // const query = this.$route.query;
        // this.code = query.code;
        // this.fullPath = query.url;
        // console.log('this.fulPath', this.fullPath)
        // this.fullPath = this.fullPath.replace('/middle?url=', '');
        // console.log('this.fulPath', this.fullPath)
        // localStorage.setItem('code', query.code);
        // localStorage.setItem('redirectUrl', this.fullPath);
        // // debugger;
        // // console.log('query', query);
        // this.onLogin();
    },
    methods: {
        async onLogin() {
            try {
                const res = await onWechatLogin({
                    code: this.code
                });
                if (res.code == 200) {
                    localStorage.setItem('token', res.data.token);
                    console.log('this.fullPaht', this.fullPath)
                    this.$router.push(this.fullPath);
                } else if (res.code == 1004) {
                    // 激活页,无需登陆
                    if (this.fullPath.search(/\/rights\?/) > -1){
                        this.$router.push(this.fullPath);
                    } else {
                        // 1004 需要注册
                        this.$router.push('/login');
                    }
                } 

            } catch(err) {
                console.log('err', err);
            }
        }
    }
}
</script>