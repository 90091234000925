<template>
    <div class="progress-page">
        <div class="rightDetail-step">
            <div class="reserve_box">
                <div class="minipro">
                    <div class="stepList">
                        <p class="num active_num">1</p>
                        <p class="name active_name">预约告知</p>
                        <img src="@/assets/progress.png" class="img">
                    </div>
                    <div class="stepList">
                        <p class="num active_num">2</p>
                        <p class="name active_name">预约申请</p>
                        <img src="@/assets/progress1.png" class="img">
                    </div>
                    <div class="stepList">
                        <p class="num">3</p>
                        <p class="name">预约安排</p>
                        <img src="@/assets/progress1.png" class="img">
                    </div>
                    <div class="stepList">
                        <p class="num">4</p>
                        <p class="name">预约完成</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="content">
            <div class="box margin-bottom-sm">
                <h4 class="box-title">
                    <div class="box-title-con">
                        <i style="background: rgb(0, 93, 204);"></i>
                        <span>预约权益</span>
                    </div>
                </h4>
                <div class="box-select flex flex-column-center flex-between" @click="show = true">
                    <p>{{ privilegeName }}</p>
                    <van-icon name="arrow-down" />
                </div>
                <van-action-sheet
                    v-model="show"
                    title="请选择预约的权益"
                    safe-area-inset-bottom
                >
                <div class="content-radio">
                    <van-radio-group v-model="privilegeId">
                        <van-cell-group>
                            <!-- privilegeList -->
                            <van-cell v-for="(item, index) in privilegeList" :key="index" :title="item.privilegeName" clickable @click="privilegeId = item.privilegeId+''">
                                <template #right-icon>
                                    <van-radio :name='`${item.privilegeId}`'>
                                        <template #icon="props">
                                        <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon" />
                                        </template>
                                    </van-radio>
                                </template>
                            </van-cell>
                        </van-cell-group>
                    </van-radio-group>
                </div>
                <div class="text-center padding-confirm">
                    <van-button type="info" round block @click="onHandleSelectRights">确定</van-button>
                </div>
                </van-action-sheet>
                <div class="box-explain">
                    *当前权益剩余 {{ surplusCount }} 次
                </div>
            </div>
            <div class="box margin-bottom-sm">
                <h4 class="box-title">
                    <div class="box-title-con">
                        <i style="background: rgb(0, 93, 204);"></i>
                        <span>权益人信息</span>
                    </div>
                </h4>
                <van-form>
                    <!-- <van-field
                        v-model="personName"
                        error
                        required
                        readonly
                        name="姓名"
                        label="姓名"
                        placeholder="用户名"
                        :rules="[{ required: true, message: '请填写用户名' }]"
                    /> -->

                    <van-cell-group required>
                        <van-field required readonly v-model="personName" label="姓名"/>
                    </van-cell-group>
                    <van-cell-group required>
                        <van-field required readonly v-model="cardType" label="证件类型" />
                    </van-cell-group>
                    <van-cell-group required>
                        <van-field required readonly v-model="credentialsNumber" label="证件号码" placeholder="请输入用户名" />
                    </van-cell-group>
                    <div class="idcard-demo">
                        <p class="error-tips tips">请上传权益人证件照片</p>
                        <div class="padding-lr-large flex">
                            <van-uploader
                                :max-count="1"
                                v-model="credentialsFrontUrl"
                                name="credentialsFrontUrlArray"
                                :before-delete="beforeDelete"
                                :after-read="afterRead"
                                upload-text="点击上传"
                            />
                            <van-uploader
                                :max-count="1"
                                v-model="credentialsBackUrl"
                                name="credentialsBackUrlArray"
                                :before-delete="beforeDelete"
                                :after-read="afterRead"
                                upload-text="点击上传"
                            />
                        </div>
                        <div class="flex case-content">
                            <div class="case" @click="previewImg(1)">
                                <img src="@/assets/idCard-front.png" />
                                <p><span>照片示例</span></p>
                            </div>
                            <div class="case" @click="previewImg(2)">
                                <img src="@/assets/idCard-back.png" />
                                <p><span>照片示例</span></p>
                            </div>
                        </div>
                    </div>
                    <!-- <van-cell title="性别" :value="sex" @click="showPicker = true" is-link /> -->
                    <van-cell-group required>
                        <van-field required readonly v-model="sex" label="性别" />
                    </van-cell-group>
                    <van-cell-group required>
                        <van-field required readonly v-model="birthday" label="出生日期" />
                    </van-cell-group>
                    <!-- 姓别 -->
                    <!-- <van-popup v-model="showPicker" position="bottom">
                        <van-picker
                            show-toolbar
                            :columns="columns"
                            @confirm="onConfirm"
                            @cancel="showPicker = false"
                        />
                    </van-popup>
                    <van-cell required title="出生日期" :value="birthday" @click="showBirthday = true" is-link /> -->
                   
                    <!-- 出生日期 -->
                    <!-- <van-popup v-model="showBirthday" position="bottom">
                        <van-datetime-picker
                            v-model="currentDate"
                            type="date"
                            title="选择年月日"
                            :min-date="minDate"
                            :max-date="maxDate"
                            @confirm="onBirthdaySelect"
                            @cancel="showBirthday = false"
                        />
                    </van-popup> -->
                    <van-cell-group required>
                        <van-field required :readonly="phoneFlag" v-model="personPhone" label="手机号" placeholder="请输入手机号" />
                    </van-cell-group>
                    <van-cell required title="所在城市" :value="cityValue" @click="cityVisible = true" is-link />
                    <!-- 城市选择 -->
                    <van-popup
                        v-model="cityVisible"
                        round
                        position="bottom"
                        :style="{ height: '50%' }"
                    >
                        <van-area
                            title="请选择您的所在城市"
                            :area-list="areaList"
                            :value="regionCode"
                            @confirm="onSelectCity"
                            @cancel="onCancleSelectCity"
                        />
                    </van-popup>
                    <van-cell required center>
                        <van-field class="personal-height" v-model="height" label="身高" placeholder="请输入身高" />
                        <template #extra>
                            <span>cm</span>
                        </template>
                    </van-cell>
                    <van-cell required center>
                        <van-field class="personal-height" v-model="weight" label="体重" placeholder="请输入体重" />
                        <template #extra>
                            <span>kg</span>
                        </template>
                    </van-cell>
                </van-form>
            </div>
            <div class="box margin-bottom-sm">
                <h4 class="box-title">
                    <div class="box-title-con">
                        <i style="background: rgb(0, 93, 204);"></i>
                        <span>预约就诊信息</span>
                    </div>
                </h4>
                <van-form>
                    <van-cell required title="就诊时间" :value="docDate" @click="showDocDate = true" is-link />
                    <!-- 就诊时间 -->
                    <van-popup v-model="showDocDate" position="bottom">
                        <van-datetime-picker
                            v-model="applyDate"
                            type="date"
                            title="选择年月日"
                            :min-date="minDocDate"
                            :max-date="maxDocDate"
                            @confirm="onBirthdaySelect"
                            @cancel="showDocDate = false"
                        />
                    </van-popup>
                    <van-cell-group>
                        <van-field
                            class="desc"
                            required
                            v-model="applyDesc"
                            rows="2"
                            label="需求描述 / 病情描述"
                            type="textarea"
                            placeholder="输入详细的病情描述，当前用药及治疗方案、和您的需求等 "
                        />
                    </van-cell-group>
                </van-form>
            </div>
            <div class="box margin-bottom-sm">
                <h4 class="box-title">
                    <div class="box-title-con">
                        <i style="background: rgb(0, 93, 204);"></i>
                        <span>医学证明材料</span>
                    </div>
                </h4>
                <div>
                    <p class="error-tips">既往门/急诊及住院病历</p>
                    <p class="pd-left">
                        <van-uploader
                            :max-count="9"
                            name="caseListArray"
                            v-model="caseList"
                            :before-delete="beforeDelete"
                            :after-read="afterRead"
                            upload-text="点击上传"
                        />
                    </p>
                </div>

                <div>
                    <p class="error-tips">诊断报告</p>
                    <p class="pd-left">
                        <van-uploader
                            :max-count="9"
                            name="diagnosticReportArray"
                            v-model="diagnosticReport"
                            :before-delete="beforeDelete"
                            :after-read="afterRead"
                            upload-text="点击上传"
                        />
                    </p>
                </div>
                <div class="pd-none">
                    <p class="error-tips">其他</p>
                    <p class="pd-left">
                        <van-uploader
                            :max-count="9"
                            name="otherListArray"
                            v-model="otherList"
                            :before-delete="beforeDelete"
                            :after-read="afterRead"
                            upload-text="点击上传"
                        />
                    </p>
                </div>
                
            </div>
        </div>
        <div class="progress-page_footer fixed">
            <div class="btn-options">
                <van-button type="info" :disabled="btnDisabled" round block @click="goPage">下一步</van-button>
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment';
moment().format();
const cardTypeFilter = {
    1: '身份证',
    2: '护照',
    3: '港澳内地通行证',
    4: '台胞证'
}
const sexFilter = {
    0: '男',
    1: '女'
}
import { areaList } from '@vant/area-data';
import { onFetchRightsUsedInfo, onUploadImage } from '@/api';
import { ImagePreview, Toast } from 'vant';
export default {
    data() {
        return {
            activeIcon: require('@/assets/checked.png'),
            inactiveIcon: require('@/assets/check-none.png'),
            show: false,
            areaList,
            cityVisible: false, // city flag
            showBirthday: false, // birthday flag
            minDate: new Date(1940, 0, 1),
            maxDate: new Date(2099, 11, 31),
            currentDate: new Date(),
            showDocDate: false, // 就诊时间 
            docDate: null, // 就诊时间 
            minDocDate: new Date(),
            maxDocDate: moment(new Date()).add(1, 'y')._d,
            birthday: '', // 出生日期
            personName: '', // 就诊人
            credentialsNumber: '', // 身份证号
            cardType: '',
            phoneFlag: true, // 是否可以输入手机号
            personPhone: '', // 手机号
            privielgeImgUrl: '', // 权益图片
            privilegeName: '', // 权益名
            privilegeId: '',
            surplusCount: 0, // 权益剩余次数
            credentialsFrontUrl: [], // 身份证正面
            credentialsFrontUrlArray: [], // 身份证正面传的值
            credentialsBackUrl: [], // 身份证反面
            credentialsBackUrlArray: [], // 身份证反面传过去的值
            height: "",
            weight: "",
            applyDate: "",
            applyDesc: "",
            regionCode: "",
            cityValue: '', // 所在城市
            caseList: [],
            diagnosticReport: [],
            otherList: [],
            caseListArray: [],
            diagnosticReportArray: [],
            otherListArray: [],
            username: '',
            sex: '',
            columns: ['男','女'],
            showPicker: false,
            btnDisabled: false, // 是否可以点击下一步
            uploader1: [],
            uploader2: [],
            uploader3: [],
            uploader4: [],
            uploader5: [],
            uploader6: [],
            uploader7: [],
            uploader8: [],
            uploader9: [],
            privilegeList: [], // 权益列表
        }
    },
    created() {
        this.privilegeId = localStorage.getItem('privilegeId');
        this.personId = localStorage.getItem('personId');
        this.init();
    },
    methods: {
        // 选择权益
        onHandleSelectRights() {
            this.onSelectRights();
            this.show = false;
        },
        // 选择权益
        onSelectRights() {
            console.log('this.privilegeList', this.privilegeList)
            console.log('this.privilegeId', this.privilegeId)
            this.privilegeList.forEach(v => {
                if (v.privilegeId == this.privilegeId) {
                    this.privielgeImgUrl = v.privielgeImgUrl;
                    this.privilegeName = v.privilegeName;
                    this.surplusCount = v.surplusCount;
                }
            })
        },
        async init() {
            try {
                let params = {
                    privilegeId: this.privilegeId
                }

                if (this.personId) {
                    Object.assign(params, {
                        personId: this.personId
                    })
                }
                const res = await onFetchRightsUsedInfo(params);
                if(res.code == 200) {
                    console.log('res', res);
                    const { data } = res;
                    this.personName = data.personName;
                    this.privilegeList = data.privilegeList;
                    this.credentialsNumber = data.credentialsNumber;
                    this.cardType = cardTypeFilter[data.credentialsType];

                    this.sex = sexFilter[data.sexInt];
                    this.birthday = data.birthday;
                    this.personPhone = data.personPhone;
                    this.phoneFlag = this.personPhone ? true: false;
                    this.regionCode = data.regionCode;
                    // 选择权益
                    this.onSelectRights();
                    // res.data.privilegeList.forEach(v => {
                    //     if (v.privilegeId == this.privilegeId) {
                    //         this.privielgeImgUrl = v.privielgeImgUrl;
                    //         this.privilegeName = v.privilegeName;
                    //     }
                    // })
                }
            } catch(err) {
                console.log('err', err);
            }
        },
        // 选择城市
        onSelectCity(list) {
            console.log('list', list);
            this.cityVisible = false;

            this.cityValue = '';
            list.forEach(v => {
                this.cityValue += v.name;
            });
            console.log('list[list.length - 1]', list[list.length - 1])
            this.regionCode = list.reverse()[0].code;
            console.log('this.regionCode', this.regionCode)
        },
        // 取消选择城市
        onCancleSelectCity() {
            this.cityVisible = false;
        },
        // 选择生日
        onBirthdaySelect(date) {
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let day = date.getDate();
            this.docDate = `${year}-${month}-${day}`;
            this.showDocDate = false;
        },
        // 身份证预览
        previewImg(type) {
            const imageJson = {
                1: require('@/assets/idCard-front.png'),
                2: require('@/assets/idCard-back.png')
            }
            ImagePreview([imageJson[type]]);
        },
        beforeDelete(file, name) {
            console.log('before delete', name);
            this[name.name] = [];
        },
        // 上传
        async afterRead(file, obj) {
            const { name } = obj;
            // 此时可以自行将文件上传至服务器
            console.log('name', name);
            console.log('file', file)
            try {
                const res = await onUploadImage({
                    file: file.file
                });
                console.log('res', res);
                if(res.code == 200) {
                    const listType = ['caseListArray', 'diagnosticReportArray', 'otherListArray'];
                    console.log('listType.includes(name)', listType.includes(name))
                    if (listType.includes(name)) {
                        this[`${name}`].push(res.data.imgUrl);
                    } else {
                        this[`${name}`] = res.data.imgUrl;
                    }
                    console.log('this', this);
                }
            } catch(err) {
                console.log('err', err);
            }

        },
        // 性别
        onConfirm(value) {
            this.sex = value;
            this.showPicker = false;
        },
        onHandleChange() {
            this.btnDisabled = false;
        },
        goPage() {
             let params = {
                personName: this.personName,
                privielgeImgUrl: this.privielgeImgUrl,
                privilegeName: this.privilegeName,
                privilegeId: this.privilegeId,
                credentialsFrontUrl: this.credentialsFrontUrlArray,
                credentialsBackUrl: this.credentialsBackUrlArray,
                height: this.height,
                weight: this.weight,
                applyDate: this.applyDate,
                applyDesc: this.applyDesc,
                phone: this.personPhone,
                regionCode: this.regionCode,
                caseList: this.caseListArray,
                diagnosticReport: this.diagnosticReportArray,
                otherList: this.otherListArray
            }
            if (this.personId) {
                Object.assign(params, {
                    personId: this.personId
                })
            }
            console.log('params', params)
            if (this.credentialsFrontUrlArray.length == 0) {
                Toast('请上传证件正面');
                return false;
            }
            if (this.credentialsBackUrlArray.length == 0) {
                Toast('请上传证件反面');
                return false;
            }
            if (!this.regionCode) {
                Toast('请选择所在城市');
                return false;
            }
            if (!this.height) {
                Toast('请填写身高');
                return false;
            }
            if (!this.weight) {
                Toast('请填写体重');
                return false;
            }
            if (!this.applyDate) {
                Toast('请选择就诊时间');
                return false;
            }
            if (!this.applyDesc) {
                Toast('请填写病情描述');
                return false;
            }
               
                localStorage.setItem('info', JSON.stringify(params));
                this.$router.push('/progressStep3');
        }
    }
}
</script>
<style scoped lang="less">
.content-radio {
    max-height: 400px;
    overflow-y: auto;
    .van-cell__title {
        text-align: center;
    }
    .img-icon {
        width: 20px;
    }
}
.padding-confirm {
    padding: 10px 20px;
}
.desc {
    letter-spacing: 1px;
}
.personal-height {
    padding: 0;
}
.progress-page {
    padding: 0 20px 70px;
    .minipro {
        display: flex;
        margin: 20px auto;
    }
    .stepList{
        flex:1;
        position:relative;

        .num {
            width: 30px;
            height: 30px;
            font-size: 16px;
            font-weight:500;
            text-align:center;
            line-height: 30px;
            margin:0 auto;
            background:#fff;
            border-radius:50%;
            color:#ccc;
            border:1px solid #ccc;
            &.active_num{
                background:#005dcc;
                border-radius:50%;
                color:#fff;
            }
        }
        .active_num{
            background:#005dcc;
            border-radius:50%;
            color:#fff;
        }
        .name{
            color:#ccc;
            font-size: 12px;
            font-weight: 500;
            margin-top: 7px;
            text-align: center;
            line-height: 18px;
            &.active_name {
                color:#005dcc;
            }
        }
        .img{
            width: 12px;
            position:absolute;
            right:-6px;
            top: 10px;
        }
    }
    &_footer {
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: #fff;
        z-index: 9;
        font-size: 14px;
        .explian {
            justify-content: space-between;
            height: 60px;
            line-height: 60px;
            padding: 0 20px;
        }
        .btn-options {
            padding: 10px 20px;
            border-top: 1px solid #f4f4f4;
        }
    }
}

.pd-left {
    padding: 10px 16px;
    border-bottom: 1px solid rgb(244, 244, 244);
   
}
.pd-none {
    .pd-left {
        border-bottom: none;
        padding-bottom: 0;
    }
}
.error-tips {
    position: relative;
    font-size: 14px;
    color: #646566;
    padding: 10px 16px;
    // padding: 0.26667rem 0.42667rem;
    &.tips {
        &::before {
            content: '*';
            position: absolute;
            left: 8px;
            top: 15px;
            color: #ee0a24;
            font-size: 0.37333rem;
        }
    }
    
}
.idcard-demo {
    .case-content {
        padding: 0 20px;
        .case {
            position: relative;
            width: 83px;
            height: 83px;
            margin-right: 5px;
            border-radius: 5px;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
            }
            p {
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                right: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                background: rgba(0, 0, 0, .7);
                span {
                    font-size: 14px;
                    color: #fff;
                }
            }
        }
    }
}
.box {
    &-select {
        border: 1px solid #005dcc;
        border-radius: 8px;
        padding: 10px 15px;
        margin: 20px 10px 10px;
        color: #323233;
        font-size: 16px;
    }
    &-explain {
        color: #005dcc;
        padding: 0 10px;
        font-size: 12px;
    }
}
</style>