<template>

    <div>
        <div class="banner">
            <img v-if="publicType == 2" src="@/assets/rights_banner.jpg" />
        </div>
        <div class="card">
        <div v-if="showFormTable">
            <div class="minipro">
                <div v-if="publicType == 1" class="stepList">
                    <p :class="publicType == '3' ? 'active_num_yishu' : 'active_num'">1</p>
                    <p :class="publicType == '3' ? 'active_name_yishu' : 'active_name'">输入卡密绑定权益人</p>
                    <img src="@/assets/progress.png" />
                </div>
                <div v-if="publicType == 1" class="stepList">
                    <p class="num">2</p>
                    <p class="name">完成激活</p>
                </div>
                <div v-if="publicType != 1" class="stepList">
                    <p :class="publicType == '3' ? 'active_num_yishu' : 'active_num'">1</p>
                    <p :class="publicType == '3' ? 'active_name_yishu' : 'active_name'">确认健康告知</p>
                    <img src="@/assets/progress.png" />
                </div>
                <div v-if="publicType != 1" class="stepList">
                    <p :class="publicType == '3' ? 'active_num_yishu' : 'active_num'">2</p>
                    <p :class="publicType == '3' ? 'active_name_yishu' : 'active_name'">输入卡密绑定权益人</p>
                    <img src="@/assets/progress.png" />
                </div>
                <div v-if="publicType != 1" class="stepList">
                    <p class="num">3</p>
                    <p class="name">完成激活</p>
                </div>
        </div>
        <div class="form-box">
            <van-form>
                <van-button  :loading="loading" style="background-color: #12cd9f;border: 0.02667rem solid #12cd9f;" round block type="info" @click="onHandleShowScane">请点击扫描激活码</van-button>
            </van-form>
        </div>
        <div class="card_info">
            <div class="title">
                <div class="tit-con">
                    <i :style="publicType == '3'? 'background: #12cd9f' : 'background: rgb(0, 93, 204)'"></i>
                    <span>绑定权益人</span>
                </div>
            </div>
            <van-form ref="form">
                <van-field
                    v-model="personName"
                    type="personName"
                    name="personName"
                    label="会员姓名"
                    placeholder="会员姓名"
                    :rules="[{ required: true, message: '请填写会员姓名' }]"
                />
    
                <van-field
                    v-model="credentialsTypeName"
                    type="credentialsType"
                    name="credentialsType"
                    label="证件类型"
                    placeholder="证件类型"
                    readonly
                    @click="showPicker = true"
                    :rules="[{ required: true, message: '请填写证件类型' }]"
                />
                <van-popup v-model="showPicker" position="bottom">
                    <van-picker
                        show-toolbar
                        :columns="columns"
                        @confirm="onConfirm"
                        @cancel="showPicker = false"
                    />
                </van-popup>
    
                <van-field
                    v-model="credentialsNumber"
                    type="credentialsNumber"
                    name="credentialsNumber"
                    label="证件号码"
                    placeholder="证件号码"
                    :rules="[{ required: true, message: '请填写证件号码' }]"
                />
    
                <van-field
                    v-model="personPhone"
                    type="personPhone"
                    name="personPhone"
                    label="手机号"
                    placeholder="手机号"
                    :rules="[{ required: true, message: '请填写手机号' }]"
                >
                </van-field>
        <!-- 
                <van-field
                    v-model="securityCode"
                    type="securityCode"
                    name="securityCode"
                    label="验证码"
                    placeholder="验证码"
                    :rules="[{ required: true, message: '请填写验证码' }]"
                /> -->
                <van-field
                    v-model="securityCode"
                    center
                    clearable
                    label="验证码"
                    placeholder="请输入短信验证码"
                    :rules="[{ required: true, message: '请填写验证码' }]"
                >
                    <template #button>
                        <span :class="publicType == '3' ? 'btn-verify-yishu' : 'btn-verify'"  @click="onHandleGetMessage">{{ btnText }}</span>
                    </template>
                </van-field>
                <!-- <van-cell class="popup-list" title="所在城市" :value="cityValue" @click="cityVisible = true" is-link /> -->
                <van-field v-if="productCode != 'HPV1'"
                    v-model="cityValue"
                    readonly
                    label="所在城市"
                    right-icon="arrow"
                    @click="cityVisible = true"
                />
                <!-- 城市选择 -->
                <van-popup
                    v-model="cityVisible"
                    round
                    position="bottom"
                    :style="{ height: '50%' }"
                >
                    <van-area
                        title="请选择您的所在城市"
                        :area-list="areaList"
                        :value="regionCode"
                        @confirm="onSelectCity"
                        @cancel="onCancleSelectCity"
                    />
                </van-popup>
                <van-field
                    v-model="salePersonName"
                    type="salePersonName"
                    name="salePersonName"
                    label="代理人姓名"
                    placeholder="请填写代理人姓名"
                >
                </van-field>
                <van-field
                    v-model="salePersonNo"
                    type="salePersonNo"
                    name="salePersonNo"
                    label="代理人工号"
                    placeholder="请填写代理人工号"
                >
                </van-field>
    
                <!-- <van-area title="标题" :area-list="areaList" confirm="onCitySelect" /> -->
                
            </van-form>
        </div>
        <div v-if="publicType == 1 && productCode != 'HPV1'" class="tips">*本权益仅适用于18周岁（含）以上成年人</div>
        <div v-if="publicType == 2" class="tips">*本权益仅适用于出生28天至{{ageMax}}周岁（含）</div>
        <div style="margin: 16px;">
            <div class="agreement flex margin-bottom-sm">
                <van-checkbox :class="publicType == '3' ? 'yiShu' : 'other'" shape="square" icon-size="16px" v-model="checked"></van-checkbox>
                <div class="flex-1 padding-left-sm">
                    <span>我已阅读并同意</span>
                    <span :class="publicType == '3' ? 'text-link-yishu' : 'text-link'" @click="onShow(index)" v-for="(item, index) in agreementLists" :key="index">《{{ item.agreementName }}》</span>
                </div>
            </div>
            
            <van-button v-if="publicType == 3" style="background-color: #12cd9f;border: 0.02667rem solid #12cd9f;" :loading="loading" round block type="info" @click="onHandleCheckForm">完成激活并绑定权益人</van-button>
            <van-button v-if="publicType != 3" :loading="loading" round block type="info" @click="onHandleCheckForm">完成激活并绑定权益人</van-button>
        </div>
        </div>
        <!-- 扫一扫激活码弹窗 -->
        <van-popup
            v-model="scaneDialogFlag"
            round
            overlay-class="info-mask"
            closeable
            close-icon="close">
            <div class="info-mask_content">
                <h4 class="text-center">扫描激活二维码</h4>
                <van-button  style="background-color: #12cd9f;border: 0.02667rem solid #12cd9f;"  :loading="loading" size="small" round block type="info" @click="scanQR">扫描激活二维码</van-button>
                <van-cell-group style="height: 40px;" v-for="(item, index) in scaneForm" :key="index">
                    <van-cell
                        title=""
                        :value="scaneForm[index]"
                        title-class="flex flex-column-center"
                        value-class="flex-2 text-left padding-right-sm"
                    >
                        <template #right-icon v-if="item !== ''">
                            <div class="flex flex-column-center">
                                <van-icon name="delete-o" @click="onHandleDeleteCode(index)" />
                            </div>
                        </template>
                    </van-cell>
                </van-cell-group>
                <van-button type="primary" style="background-color: #12cd9f;border: 0.02667rem solid #12cd9f;"  size="small" round block @click="onHandleSaveCode">确认</van-button>
            </div>
        </van-popup>
        <van-popup
            v-model="show"
            round
            overlay-class="info-mask"
            closeable
            close-icon="close">
            <div class="info-mask_content">
                <h4 class="text-center">温馨提示</h4>
                <p>{{errorMsg}}  <a v-if="errorCode == '2027'" href="https://www.healthmore.net/rightsPersonList">点击跳转</a> </p>
                <p v-if="productCode != 'HPV1'">也可拨打客服电话确认。</p>
                <p v-if="productCode != 'HPV1' && (publicType =='1' || publicType =='2')">
                   <a class="tel_phone" :href="'tel:' + 4000133558">400-013-3558</a>
                </p>
                <p v-if="productCode != 'HPV1' && publicType =='3'">
                   <a class="tel_phone" style="color: #12cd9f;" :href="'tel:' + 4009028321">400-9028-321</a>
                </p>
                <p v-if="productCode != 'HPV1' && publicType =='4'">
                   <a class="tel_phone" :href="'tel:' + 4009028321">400-9028-321</a>
                </p>
                <p v-if="productCode == 'HPV1'">也可联系健康顾问确认。</p>
            </div>
        </van-popup>
        <!-- 内容 -->
        <van-popup
            v-model="showFlag"
            round
            closeable
            position="bottom"
            close-icon="close"
            :style="{ height: '70%' }">
            <div class="text-dialog flex flex-column" v-if="currentAgreement">
                <h2>{{ currentAgreement.agreementName }}</h2>
                <div class="text-content flex-1" v-html="currentAgreement.agreementContent"></div>
            </div>
        </van-popup>
    
        <van-popup
            v-model="registerFlag"
            position="right"
            overlay	
            duration="0"
            close-icon="close"
            :style="{ height: '100%' }">
            <div class="banner">
                <img v-if="publicType == 2" src="@/assets/rights_banner.jpg" />
            </div>
    
            <div class="text-dialog flex flex-column">
                <div class="health-content" v-html="healthyInform"></div>
                <div class="health-options">
                    <div class="flex explian">
                        <span>请确认是否有以上病症</span>
                        <div class="flex flex-column-center">
                            <van-radio-group
                                v-model="radioValue"
                                direction="horizontal"
                                @change="onHandleChange"
                            >
                                <van-radio :class="publicType == '3' ? 'yiShu' : 'other'"  name="1">有</van-radio>
                                <van-radio :class="publicType == '3' ? 'yiShu' : 'other'"  name="2">没有</van-radio>
                            </van-radio-group>
                        </div>
                    </div>
                    <div class="btn-options">
                        <van-button v-if="publicType == 3" type="info" style="background-color: #12cd9f;border: 0.02667rem solid #12cd9f;" :disabled="btnDisabled" round block @click="goPage">开始激活并绑定权益人</van-button>
                        <van-button v-if="publicType != 3" type="info" :disabled="btnDisabled" round block @click="goPage">开始激活并绑定权益人</van-button>
                    </div>
                </div>
            </div>
        </van-popup>
        </div>
    </div>
    </template>
    <script>
    import { Toast, Dialog } from 'vant';
    import { areaList } from '@vant/area-data';
    import { onActiveRights, onFetchMessage, onFetchProductInfo, onLoginOrRegister,selActivation,onActiveRightsList } from '@/api';
    import { weixinInit } from '@/utils/wechatUtil.js';
    var wx = require('weixin-js-sdk');
    
    export default {
        data() {
            return {
                scaneDialogFlag: false, // 扫描二维码dialog
                scaneForm: ['', '', '','','','','','','',''], // 激活码数组
                scaneCardNum: ['', '', '','','','','','','',''], //激活码卡号
                productPasswordArr: [], // 最终传值
                flag: false, // 新用户注册后，后端是否需要验证
                loading: false,
                showFormTable: false,
                showFlag: false,
                radioValue: '',
                btnDisabled: true,
                registerFlag: false, // 注册 modal
                isHealthyInform: false, // 是否弹出协议
                healthyInform: null, // 协议内容
                content: '',
                show: false,
                errorMsg: '',
                errorCode: '',
                checked: false,
                areaList,
                productCode: '', // 权益卡编码
                productPassword: '', // 卡密
                personName: "",
                credentialsType: 1, // 身份证类型   1 身份证 2 护照 3 港澳内地通行证 4 台胞证
                credentialsTypeName: '身份证',
                credentialsNumber: "",
                personPhone: "",
                salePersonName: "",
                salePersonNo: "",
                securityCode: "",
                regionCode: "",
                cityValue: '', // 所在城市
                time: 60,
                btnStatus: true,
                btnText: '获取验证码',
                cityVisible: false, // city flag
                showPicker: false,
                columns: ['身份证'],
                agreementLists: [], // 协议列表
                currentAgreement: null, // 选中的协议,
                publicType: '',
                ageMax: '',
                weixinShare: {
                    url: '',
                    publicType: '',
                    type:1
                },
            }
        },
        created() {
            this.productCode = this.$route.query.productCode || 'CAT1';
            this.publicType = localStorage.getItem('publicType');
            this.weixinShare.url = window.location.href.split('#')[0];
            this.weixinShare.publicType = this.publicType;
            this.init();
            this.wxConfigInit();
        },
        methods: {
            // 扫描二维码
            onHandleShowScane() {
                this.scaneDialogFlag = true;
            },
            // 删除激活码
            onHandleDeleteCode(index) {
                this.$set(this.scaneForm, index, '');
                this.$set(this.scaneCardNum, index, '');
            },
            // 保存扫描激活码code
            onHandleSaveCode() {
                const flag = this.scaneForm.some(item => item === '');
                if (flag) {
                    this.$toast('请扫描10条激活码后确认');
                    return false;
                }
                // 最终字段赋值
                this.productPasswordArr = this.scaneForm;
                this.scaneDialogFlag = false;
            },
            async wxConfigInit() {
    
                await weixinInit(this.weixinShare);
            },
            goPage() {
                if (this.radioValue == '1') {
                    if(this.publicType == 3){
                        Dialog.alert({
                        title: '温馨提示',
                        message: '非常抱歉，您暂不符合激活条件。',
                        theme: 'round-button',
                        confirmButtonColor: '#12cd9f'
                        }).then(() => {
                            // on close
                        });
                    } else {
                        Dialog.alert({
                        title: '温馨提示',
                        message: '非常抱歉，您暂不符合激活条件。',
                        theme: 'round-button',
                        confirmButtonColor: '#1989fa'
                        }).then(() => {
                            // on close
                        });
                    }
                    
                } else {
                    // this.$router.push('/progressStep2');
                    this.registerFlag = false;
                    this.showFormTable = true;
                    //this.onHandleFinalSumit();
                }
            },
            // 是否可以点击下一步
            onHandleChange() {
                this.btnDisabled = false;
            },
            // 身份证
            onConfirm(value, index) {
                this.credentialsType = index + 1;
                this.credentialsTypeName = value;
                this.showPicker = false;
            },
            onShow(index) {
                this.currentAgreement = this.agreementLists[index];
                this.showFlag = true;
            },
            // 选择城市
            onSelectCity(list) {
                console.log('list', list);
                this.cityVisible = false;
    
                this.cityValue = '';
                list.forEach(v => {
                    this.cityValue += v.name;
                });
                console.log('list[list.length - 1]', list[list.length - 1])
                this.regionCode = list.reverse()[0].code;
                console.log('this.regionCode', this.regionCode)
            },
            // 取消选择城市
            onCancleSelectCity() {
                this.cityVisible = false;
            },
            // 发送验证码
            async onHandleGetMessage() {
                if (!this.btnStatus) {
                    return false;
                }
                if (!this.personPhone) {
                    Toast('请先输入手机号');
                    return;
                }
                if (this.personPhone.length != 11) {
                    Toast('请输入正确的手机号');
                    return;
                }
                try {
                    this.btnStatus = false;
                    const res = await onFetchMessage({
                        userPhone:this.personPhone,
                        publicType: this.publicType
                    });
                    Toast('短信已发送');
                    console.log('res', res);
                    let timer = setInterval(() => {
                        if (--this.time > 0) {
                            this.btnText = `${this.time}s重新发送`;
                        } else {
                            this.btnText = '获取验证码';
                            clearInterval(timer);
                            this.btnStatus = true;
                            this.time = 60;
                        }
                    }, 1000);
                } catch(err) {
                    this.btnStatus = true;
                    console.log('err', err);
                }
            },
            // 查询说明
            async init() {
                try {
                    const res = await onFetchProductInfo({
                        productCode: this.productCode
                    });
                    if (res.code == 200) {
                        this.registerFlag = res.data.remark.isHealthyInform;
                        this.healthyInform = res.data.healthyInform;
                        if(!this.registerFlag) {
                            this.showFormTable = true;
                        }
                        this.ageMax = res.data.remark.ageEndYear;
                        this.agreementLists = res.data.productAgreementList;
                        this.isHealthyInform = res.data.remark.isHealthyInform;
                    }
                } catch(err) {
                    console.log('err', err);
                }
            },
            onHandleCheckForm() {
                this.loading = true;
                this.$refs.form.submit();
                const token = localStorage.getItem('token');
                console.log('token', token)
                if (token) {
                    this.onSubmit();
                } else {
                    this.onRegister();
                }
            },
            scanQR() {
                    const _this = this;
                    wx.ready(function () {
                        wx.scanQRCode({
                            needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                            scanType: ["qrCode"], // 可以指定扫二维码还是一维码，默认二者都有
                            success: function (res) {
                                setTimeout(function(){
                                    const result = res.resultStr;
                                    _this.doScanQR(result);
                                },1000);
                            }
                        })
                    });
                    wx.error(function(res) {
                        location.reload();
                    });
            },
            async doScanQR(scanQrParam){
                const _this = this;
                if(scanQrParam.length != 10){
                    this.$toast('请扫描正确的激活码');
                    return false;
                }
                let count = 0;
                for(let i = 0; i < _this.scaneCardNum.length; i++) {
                    if (_this.scaneCardNum[i] != '') {
                        count++;
                    }
                }
                if(count == 10){
                    this.$toast('您已扫描10张卡，无须再次扫描');
                    return false;
                }
                try {
                    const res = await selActivation({
                        activationCode: scanQrParam
                    });
                    if (res.code == 200) {
                        for(let i = 0; i < _this.scaneCardNum.length; i++) {
                            if (_this.scaneCardNum[i] === res.data) {
                                this.$toast('卡号'+ res.data +'已经存在，无法继续扫码添加');
                                return false;
                            }
                        }
                        for(let i = 0; i < _this.scaneForm.length; i++) {
                            if (_this.scaneForm[i] === '') {
                                _this.$set(_this.scaneForm, i, scanQrParam);
                                _this.$set(_this.scaneCardNum, i, res.data);
                                return false;
                            }
                        }

                    }else{
                        this.$toast(res.msg);
                        return false;
                    }
                } catch(err) {
                    this.$toast("扫描出错，请重试");
                    console.log('err', err);
                    return fasle;
                }
            },
            // 注册新用户
            async onRegister() {
                try {
                    const params = {
                        userPhone: this.personPhone,
                        securityCode: this.securityCode,
                        publicType: localStorage.getItem('publicType')
                    };
                    let code = localStorage.getItem('code');
                    if (code) {
                        Object.assign(params, {
                            code
                        })
                    }
                    const res = await onLoginOrRegister(params);
                    if (res.code == 200) {
                        localStorage.setItem('token', res.data.token);
                        this.flag = true;
                        // 提交表单
                        this.onSubmit();
                    } else {
                        this.$toast(res.msg);
                        this.loading = false;
                    }
                } catch(err) {
                    console.log('err', err);
                    this.loading = false;
                }
            },
            // 提交表单
            async onSubmit() {
                if (!this.personName || !this.credentialsNumber || !this.personPhone || !this.securityCode) {
                    this.loading = false;
                    return false;
                }
                if (this.productPasswordArr.length != 10) {
                    Toast('请扫描涂刮层下的激活码，共10张');
                    this.loading = false;
                    return false;
                }
                if (this.productCode != 'HPV1' && !this.regionCode) {
                    Toast('请选择城市');
                    this.loading = false;
                    return false;
                }
                if (!this.checked) {
                    Toast('请阅读并同意下方协议');
                    this.loading = false;
                    return false;
                }
               
                // 注册新用户
                // 注册用户接口,完成之后，继续走现有逻辑
                this.onHandleFinalSumit();
            },
            // 提交接口
            async onHandleFinalSumit() {
                let values = {
                    productCode: this.productCode,
                    activationCodeList:this.productPasswordArr,
                    personName: this.personName,
                    credentialsType: this.credentialsType,
                    credentialsNumber: this.credentialsNumber,
                    personPhone: this.personPhone,
                    salePersonName: this.salePersonName,
                    salePersonNo: this.salePersonNo,
                    securityCode: this.securityCode,
                    regionCode: this.regionCode,
                    publicType: localStorage.getItem('publicType')
                }
                if (this.flag) {
                    Object.assign(values, {
                        notLogin: this.flag
                    })
                }
                console.log('submit', values);
                try {
                    const res = await onActiveRightsList(values);
                    console.log('res', res);
                    if (res.code == 200) {
                        this.$router.push('/rightsActive');
                    } else {
                        // this.$toast(res.msg);
                        this.loading = false;
                        this.errorMsg = res.msg;
                        this.errorCode = res.code;
                        this.show = true;
                    }
                } catch(err) {
                    this.loading = false;
                    console.log('err', err);
                }
                this.registerFlag = false;
            },
            onCitySelect(item) {
                console.log('item', item);
            },
          
        }
    }
    </script>
    <style lang="less" scoped>
    
    ::v-deep .health-content {
        padding: 10px 10px;
        font-size: 14px;
        line-height: 16px;
        h2{
            font-size: 16px;
            color: #333;
            margin: 0 auto;
            padding: 20px 0;
            text-align: center;
        }
        img {
            width: 100%;
        }
    }
    ::v-deep .health-options {
        padding: 10px;
        .explian {
            font-size: 14px;
            span {
                padding-right: 10px;
            }
        }
        .btn-options {
            margin-top: 25px;
            
        }
    }
    p {
        margin: 0;
        padding: 0;
    }
    .card {
        padding: 0 15px;
    }
    .banner {
        width: 100%;
        margin: 0;
        img {
            width: 100%;
        }
    }
    .tel_phone {
        font-size: 25px;
    }
    .minipro {
        display: flex;
        justify-content: center;
        margin: 20px auto;
        .stepList {
            flex: 1;
            position: relative;
            .num {
                background: #fff;
                border-radius: 50%;
                color: #ccc;
                border: 1px solid #ccc;
            }
            .active_num,
            .num {
                width: 30px;
                height: 30px;
                font-size: 16px;
                font-weight: 500;
                text-align: center;
                line-height: 30px;
                margin: 0 auto;
            }
            .active_num_yishu,
            .num {
                width: 30px;
                height: 30px;
                font-size: 16px;
                font-weight: 500;
                text-align: center;
                line-height: 30px;
                margin: 0 auto;
            }
            .active_num {
                background: #005dcc;
                border-radius: 50%;
                color: #fff;
            }
            .active_num_yishu {
                background: #12cd9f;
                border-radius: 50%;
                color: #fff;
            }
            .active_name,
            .name {
                font-size: 12px;
                font-weight: 500;
                margin-top: 7px;
                text-align: center;
                line-height: 18px;
            }
            .active_name {
                color: #005dcc;
            }
            .active_name_yishu,
            .name {
                font-size: 12px;
                font-weight: 500;
                margin-top: 7px;
                text-align: center;
                line-height: 18px;
            }
            .active_name_yishu {
                color: #12cd9f;
            }
            img {
                width: 12px;
                position: absolute;
                right: -6px;
                top: 10px;
                border-style: none;
            }
        }
    }
    .form-box {
        margin-bottom: 13px;
    }
    .card_info {
        width: 100%;
        padding: 16px 0 0;
        border-radius: 12px;
        background: #fff;
        box-shadow: 0 2px 8px 0 rgb(0 0 0 / 4%);
        margin-bottom: 12px;
        .title {
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;
            display: flex;
            .tit-con {
                flex: 1;
                display: flex;
                align-items: center;
                i {
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 12px;
                    width: 4px;
                    height: 20px;
                    background: #005dcc;
                    border-radius: 2px;
                }
                span {
                    vertical-align: middle;
                    line-height: .26rem;
                }
            }
        }
    }
    .card .van-form {
        padding: 0 16px;
        .van-field {
            padding: 16px 0;
        }
    }
    ::v-deep .van-field .van-field__error-message {
        line-height: 12px;
        position: absolute;
        bottom: -10px;
        left: 0;
    }
    .van-field__error-message{
        color: #ee0a24;
        font-size: 12px;
        text-align: left;
    }
    .btn-verify {
        display: inline-block;
        font-size: 12px;
        background-color: #1989fa;
        color: #fff;
        padding: 5px 10px;
        border-radius: 30px;
        &.disabled {
            opacity: .7;
        }
    }
    .btn-verify-yishu {
        display: inline-block;
        font-size: 12px;
        background-color: #12cd9f;
        color: #fff;
        padding: 5px 10px;
        border-radius: 30px;
        &.disabled {
            opacity: .7;
        }
    }
    .popup-list {
        padding-left: 0 !important;
        padding-right: 0 !important;
        .van-cell__title {
            color: #646566;
        }
        .van-cell__value {
            color: #323233;
        }
    }
    .tips {
        font-size: 12px;
        color: #999;
        margin: 12px auto;
        text-align: center;
    }
    
    .agreement {
        margin-top: 20px;
        align-items: flex-start;
        font-size: 12px;
        color: #1d1d1d;
        .text-link {
            color:rgb(0, 93, 204);
        }
        .text-link-yishu {
            color:#12cd9f;
        }
    }
    ::v-deep .yiShu .van-radio__icon--checked .van-icon {
        background-color: #12cd9f;
        border-color: #12cd9f;
    }
    ::v-deep .other .van-radio__icon--checked .van-icon {
        background-color: #007aff;
        border-color: #007aff;
    }
    ::v-deep .yiShu .van-checkbox__icon--checked .van-icon {
        background-color: #12cd9f;
        border-color: #12cd9f;
    }
    ::v-deep .other .van-checkbox__icon--checked .van-icon {
        background-color: #007aff;
        border-color: #007aff;
    }
    ::v-deep .info-mask {
        background-color: rgba(0, 0, 0, .3) !important;
        &_content {
            width: 300px;
            padding: 10px 20px;
            h4 {
                margin: 0;
                padding: 10px;
                font-size: 18px;
            }
            p {
                margin-bottom: 10px;
                font-size: 14px;
                text-align: center;
            }
            .qrcode {
                width: 150px;
                height: 150px;
                margin: 10px auto;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    .text-dialog {
        height: 100%;
        color: #333;
        
        h2{
            font-size: 16px;
            color: #333;
            margin: 0 auto;
            padding: 20px 0;
            text-align: center;
        }
        .text-content {
            padding: 30px 10px;
            font-size: 14px;
            line-height: 22px;
            overflow: auto;
        }
    }
    </style>