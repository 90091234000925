<template>
    <div class="serverPdf">
        <div>
			<div class="divText"><img src="https://more-health.oss-cn-beijing.aliyuncs.com/image/ec4a0557-5a60-44c8-add4-8b09a12ea2af.jpg"></div>
			<div class="divText">甲方：{{personName}}</div>
			<div class="divText">身份证号：{{idCardNo}}</div>
			<div class="divText">服务卡号：{{activationCardNumber}}</div>
			<div class="divText">电话：{{personPhone}}</div>
			<div class="divText" style="height: 15px;"></div>
			<div class="divText">乙方：上海壹树医疗科技有限公司</div>
			<div class="divText">地址：中国(上海)自由贸易试验区纳贤路 800 号 1 幛  B 座 9 楼 902-2</div>
			<div class="divText"></div>
        <img @click="preview(0)" src="https://morehealthy.oss-cn-beijing.aliyuncs.com/1.0zhizhen/1.png">
        <img @click="preview(1)" src="https://morehealthy.oss-cn-beijing.aliyuncs.com/1.0zhizhen/2.png">
        <img @click="preview(2)" src="https://morehealthy.oss-cn-beijing.aliyuncs.com/1.0zhizhen/3.png">
        <img @click="preview(3)" src="https://morehealthy.oss-cn-beijing.aliyuncs.com/1.0zhizhen/4.png">
        <img @click="preview(4)" src="https://morehealthy.oss-cn-beijing.aliyuncs.com/1.0zhizhen/5.png">
        <img @click="preview(5)" src="https://morehealthy.oss-cn-beijing.aliyuncs.com/1.0zhizhen/6.png">
        <img @click="preview(6)" src="https://morehealthy.oss-cn-beijing.aliyuncs.com/1.0zhizhen/7.png">
        <img @click="preview(7)" src="https://morehealthy.oss-cn-beijing.aliyuncs.com/1.0zhizhen/8.png">
        <img @click="preview(8)" src="https://morehealthy.oss-cn-beijing.aliyuncs.com/1.0zhizhen/9.png">
        <img @click="preview(9)" src="https://morehealthy.oss-cn-beijing.aliyuncs.com/1.0zhizhen/10.png">
        <img @click="preview(10)" src="https://morehealthy.oss-cn-beijing.aliyuncs.com/1.0zhizhen/11.png">
        <img @click="preview(11)" src="https://morehealthy.oss-cn-beijing.aliyuncs.com/1.0zhizhen/12.png">
        <img @click="preview(12)" src="https://morehealthy.oss-cn-beijing.aliyuncs.com/1.0zhizhen/13.png">
        <img @click="preview(13)" src="https://morehealthy.oss-cn-beijing.aliyuncs.com/1.0zhizhen/14.png">
    </div>
    </div>
</template>

<script>
import { contents } from './util';
import { ImagePreview } from 'vant';
import { weixinInit } from '@/utils/wechatUtil.js';
import { selUserPrivilegeDetail  } from '@/api';
export default {
    data() {
        return {
           personName: '',
           idCardNo: '',
           activationCardNumber: '',
           personPhone: '',
           personPrivilegeId: '',
        }
    },
    created() {
        this.personPrivilegeId = this.$route.query.personPrivilegeId;
        this.init();
    },
    methods: {
        async preview(index) {
            ImagePreview({
            images:[
                'https://morehealthy.oss-cn-beijing.aliyuncs.com/1.0zhizhen/1.png',
                'https://morehealthy.oss-cn-beijing.aliyuncs.com/1.0zhizhen/2.png',
                'https://morehealthy.oss-cn-beijing.aliyuncs.com/1.0zhizhen/3.png',
                'https://morehealthy.oss-cn-beijing.aliyuncs.com/1.0zhizhen/4.png',
                'https://morehealthy.oss-cn-beijing.aliyuncs.com/1.0zhizhen/5.png',
                'https://morehealthy.oss-cn-beijing.aliyuncs.com/1.0zhizhen/6.png',
                'https://morehealthy.oss-cn-beijing.aliyuncs.com/1.0zhizhen/7.png',
                'https://morehealthy.oss-cn-beijing.aliyuncs.com/1.0zhizhen/8.png',
                'https://morehealthy.oss-cn-beijing.aliyuncs.com/1.0zhizhen/9.png',
                'https://morehealthy.oss-cn-beijing.aliyuncs.com/1.0zhizhen/10.png',
                'https://morehealthy.oss-cn-beijing.aliyuncs.com/1.0zhizhen/11.png',
                'https://morehealthy.oss-cn-beijing.aliyuncs.com/1.0zhizhen/12.png',
                'https://morehealthy.oss-cn-beijing.aliyuncs.com/1.0zhizhen/13.png',
                'https://morehealthy.oss-cn-beijing.aliyuncs.com/1.0zhizhen/14.png'
            ],
            startPosition: index,
            closeable: true,
        });
        },
        async init() {
            try {
                const res = await selUserPrivilegeDetail({
                    personPrivilegeId: this.personPrivilegeId
                });
                if (res.code == 200) {
                    this.personName = res.data[0].personName;
                    this.idCardNo = res.data[0].credentialsNumber;
                    this.activationCardNumber = res.data[0].activationCardNumber;
                    this.personPhone = res.data[0].personPhone;
                } else if (res.code == 2014) {
                    // 无权益
                    this.rightsVisable = false;
                }
            } catch(err) {
                console.log('err', err);
            }
        }
    }
}
</script>
<style scoped lang="less">
.serverPdf {
    width: 100%;
    margin: 0;
    img {
        width: 100%;
    }
}
.divText {
    margin-left: 25px;
    width: 330px;
    font-size: 10px;
    line-height: 20px;
}
</style>