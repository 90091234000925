<template>

<div>
    <div class="card">
    <div v-if="showFormTable">
        <div class="minipro">
            <div class="stepList">
                <p class="active_name">国药特药建档登记</p>
            </div>
           
    </div>
    <div class="card_info">
        <div class="title">
            <div class="tit-con">
                <i style="background: rgb(0, 93, 204);"></i>
                <span>会议信息</span>
            </div>
        </div>
        <van-form ref="form">
            <van-field
                v-model="meetingName"
                type="personName"
                label="会议名称"
                disabled
            />
            <van-field
                v-model="meetingTime"
                type="personPhone"
                label="会议时间"
                disabled
            >   
            </van-field>
        </van-form>
    </div>
    <div class="card_info">
        <div class="title">
            <div class="tit-con">
                <i style="background: rgb(0, 93, 204);"></i>
                <span>建档信息填写</span>
            </div>
        </div>
        <van-form ref="form">
            <van-field
                v-model="personName"
                type="personName"
                name="personName"
                label="姓名"
                placeholder="姓名"
                :rules="[{ required: true, message: '请填写姓名' }]"
            />
            <van-field
                v-model="personPhone"
                type="personPhone"
                name="personPhone"
                label="手机号"
                placeholder="手机号"
                :rules="[{ required: true, message: '请填写手机号' }]"
            >   
            </van-field>
            <van-field
                v-model="credentialsNumber"
                type="credentialsNumber"
                name="credentialsNumber"
                label="身份证号"
                placeholder="身份证号"
                :rules="[{ required: true, message: '请填写身份证号' }]"
            >
            </van-field>
            
        </van-form>
    </div>
    
    <div style="margin: 16px;">
        <van-button :loading="loading" round block type="info" @click="onHandleCheckForm">提交建档信息</van-button>
    </div>
    </div>
   
    <!-- 内容 -->
    <van-popup
        v-model="showFlag"
        round
        closeable
        position="bottom"
        close-icon="close"
        :style="{ height: '70%' }">
        <div class="text-dialog flex flex-column" v-if="currentAgreement">
            <h2>{{ currentAgreement.agreementName }}</h2>
            <div class="text-content flex-1" v-html="currentAgreement.agreementContent"></div>
        </div>
    </van-popup>

    </div>
</div>
</template>
<script>
import { Toast, Dialog, Loading, Overlay } from 'vant';
import { areaList } from '@vant/area-data';
import { selectMeetingById, onFetchMessage, registerMeeting } from '@/api';
export default {
    data() {
        return {
            flag: false, // 新用户注册后，后端是否需要验证
            loading: false,
            showFormTable: true,
            showFlag: false,
            radioValue: '',
            btnDisabled: true,
            registerFlag: false, // 注册 modal
            isHealthyInform: false, // 是否弹出协议
            healthyInform: null, // 协议内容
            content: '',
            show: false,
            errorMsg: '',
            checked: false,
            areaList,
            productCode: '', // 权益卡编码
            productPassword: '', // 卡密
            personName: "",
            credentialsType: 1, // 身份证类型   1 身份证 2 护照 3 港澳内地通行证 4 台胞证
            credentialsTypeName: '身份证',
            credentialsNumber: "",
            personPhone: "",
            salePersonName: "",
            salePersonNo: "",
            securityCode: "测试验证",
            regionCode: "",
            cityValue: '', // 所在城市
            time: 60,
            btnStatus: true,
            btnText: '获取验证码',
            cityVisible: false, // city flag
            showPicker: false,
            columns: ['身份证'],
            agreementLists: [], // 协议列表
            currentAgreement: null, // 选中的协议,
            publicType: '',
            price: '',
            remark:'',
            saleOrgName: '',
            urlString:'',
            meetingId:'',
            meetingName:'',
            meetingTime:''
        }
    },
    created() {
        this.meetingId = this.$route.query.meetingId;
        this.publicType = localStorage.getItem('publicType');
        this.init();
    },
    methods: {
        goPage() {
            if (this.radioValue == '1') {
                Dialog.alert({
                    title: '温馨提示',
                    message: '非常抱歉，您暂不符合激活条件。',
                    theme: 'round-button',
                    confirmButtonColor: '#1989fa'
                }).then(() => {
                // on close
                });
            } else {
                // this.$router.push('/progressStep2');
                this.registerFlag = false;
                this.showFormTable = true;
                //this.onHandleFinalSumit();
            }
        },
        // 是否可以点击下一步
        onHandleChange() {
            this.btnDisabled = false;
        },
        onShow(index) {
            this.currentAgreement = this.agreementLists[index];
            this.showFlag = true;
        },
        // 发送验证码
        async onHandleGetMessage() {
            if (!this.btnStatus) {
                return false;
            }
            if (!this.personPhone) {
                Toast('请先输入手机号');
                return;
            }
            if (this.personPhone.length != 11) {
                Toast('请输入正确的手机号');
                return;
            }
            try {
                const res = await onFetchMessage({
                    userPhone:this.personPhone,
                    publicType: 1
                });
                Toast('短信已发送');
                console.log('res', res);
                this.btnStatus = false;
                let timer = setInterval(() => {
                    if (--this.time > 0) {
                        this.btnText = `${this.time}s重新发送`;
                    } else {
                        this.btnText = '获取验证码';
                        clearInterval(timer);
                        this.btnStatus = true;
                        this.time = 60;
                    }
                }, 1000);
            } catch(err) {
                this.btnStatus = true;
                console.log('err', err);
            }
        },
        async init() {
            try {
                const res = await selectMeetingById({
                    "meetingId": this.meetingId
                });
                if (res.code == 200) {
                    this.meetingName = res.data.meetingName;
                    this.meetingTime = res.data.meetingTimeString;
                } else {
                    Dialog.alert({
                    title: '温馨提示',
                    message: '当前建档已结束，请勿登记',
                    theme: 'round-button',
                    confirmButtonColor: '#1989fa'
                    }).then(() => {
                        // on close
                    });
                }
            } catch(err) {
                console.log('err', err);
            }
        },
        onHandleCheckForm() {
            this.onSubmit();
        },
        // 提交表单
        async onSubmit() {
            if (!this.personName) {
                Toast('请填写姓名');
                return false;
            }
            if (!this.personPhone || !this.credentialsNumber || !this.meetingId) {
                Toast('请填写手机号');
                return false;
            }
            if (!this.credentialsNumber) {
                Toast('请填写身份证号');
                return false;
            }
            this.loading = true;
            this.$refs.form.submit();
            this.onHandleFinalSumit();
        },
        // 提交接口
        async onHandleFinalSumit() {
            let values = {
                personName: this.personName,
                personPhone: this.personPhone,
                credentialsNumber: this.credentialsNumber,
                meetingId: this.meetingId
            }
            console.log('submit', values);
            try {
                const res = await registerMeeting(values);
                if (res.code == 200) {
                    this.$router.push('/meetingSuccess');
                } else {
                    this.$toast(res.msg);
                    this.loading = false;
                }
            } catch(err) {
                console.log('err', err);
                this.loading = false;
            }
            this.registerFlag = false;
        },
        onCitySelect(item) {
            console.log('item', item);
        },
    }
}
</script>
<style lang="less" scoped>

::v-deep .health-content {
    padding: 10px 10px;
    font-size: 14px;
    line-height: 16px;
    h2{
        font-size: 16px;
        color: #333;
        margin: 0 auto;
        padding: 20px 0;
        text-align: center;
    }
    img {
        width: 100%;
    }
}
::v-deep .health-options {
    padding: 10px;
    .explian {
        font-size: 14px;
        span {
            padding-right: 10px;
        }
    }
    .btn-options {
        margin-top: 25px;
        
    }
}
p {
    margin: 0;
    padding: 0;
}
.card {
    padding: 0 15px;
}
.banner {
    width: 100%;
    margin: 0;
    img {
        width: 100%;
    }
}
.tel_phone {
    font-size: 25px;
}
.minipro {
    display: flex;
    justify-content: center;
    margin: 20px auto;
    .stepList {
        flex: 1;
        position: relative;
        .num {
            background: #fff;
            border-radius: 50%;
            color: #ccc;
            border: 1px solid #ccc;
        }
        .active_num,
        .num {
            width: 30px;
            height: 30px;
            font-size: 16px;
            font-weight: 500;
            text-align: center;
            line-height: 30px;
            margin: 0 auto;
        }
        .active_num {
            background: #005dcc;
            border-radius: 50%;
            color: #fff;
        }
        .active_name,
        .name {
            font-size: 12px;
            font-weight: 500;
            margin-top: 7px;
            text-align: center;
            line-height: 18px;
        }
        .active_name {
            color: #005dcc;
            font-size: 20px;
        }
        img {
            width: 12px;
            position: absolute;
            right: -6px;
            top: 10px;
            border-style: none;
        }
    }
}
.form-box {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 2px 8px 0 rgb(0 0 0 / 4%);
    margin-bottom: 13px;
}
.card_info {
    width: 100%;
    padding: 16px 0 0;
    border-radius: 12px;
    background: #fff;
    box-shadow: 0 2px 8px 0 rgb(0 0 0 / 4%);
    margin-bottom: 12px;
    .title {
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        display: flex;
        .tit-con {
            flex: 1;
            display: flex;
            align-items: center;
            i {
                display: inline-block;
                vertical-align: middle;
                margin-right: 12px;
                width: 4px;
                height: 20px;
                background: #005dcc;
                border-radius: 2px;
            }
            span {
                vertical-align: middle;
                line-height: .26rem;
            }
        }
    }
}
.card .van-form {
    padding: 0 16px;
    .van-field {
        padding: 16px 0;
    }
}
::v-deep .van-field .van-field__error-message {
    line-height: 12px;
    position: absolute;
    bottom: -10px;
    left: 0;
}
.van-field__error-message{
    color: #ee0a24;
    font-size: 12px;
    text-align: left;
}
.btn-verify {
    display: inline-block;
    font-size: 12px;
    background-color: #1989fa;
    color: #fff;
    padding: 5px 10px;
    border-radius: 30px;
    &.disabled {
        opacity: .7;
    }
}
.popup-list {
    padding-left: 0 !important;
    padding-right: 0 !important;
    .van-cell__title {
        color: #646566;
    }
    .van-cell__value {
        color: #323233;
    }
}
.tips {
    font-size: 12px;
    color: #999;
    margin: 12px auto;
    text-align: center;
}
.loadingDiv {
    position: absolute;
    left: 42%;
    top: 40%;
}
.agreement {
    margin-top: 20px;
    align-items: flex-start;
    font-size: 12px;
    color: #1d1d1d;
    .text-link {
        color:rgb(0, 93, 204);
    }
}

::v-deep .info-mask {
    background-color: rgba(0, 0, 0, .3) !important;
    &_content {
        width: 300px;
        padding: 10px 20px;
        h4 {
            margin: 0;
            padding: 10px;
            font-size: 18px;
        }
        p {
            margin-bottom: 10px;
            font-size: 14px;
            text-align: center;
        }
        .qrcode {
            width: 150px;
            height: 150px;
            margin: 10px auto;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}
.text-dialog {
    height: 100%;
    color: #333;
    
    h2{
        font-size: 16px;
        color: #333;
        margin: 0 auto;
        padding: 20px 0;
        text-align: center;
    }
    .text-content {
        padding: 30px 10px;
        font-size: 14px;
        line-height: 22px;
        overflow: auto;
    }
}
</style>