<template>
    <div class="rightDetail">
        <div class="my-swiper">
            <swiper
                class="swiper"
                :options="swiperOption"
                ref="mySwiper"
                @slidePrevTransitionEnd="onHandlePrewEnd"
                @slideNextTransitionEnd="onHandleNextEnd"
            >
                <swiper-slide v-for="(item, index) in rightsList" :key="index">
                    <div class="my-swiper_item">
                        <img :src="item.privilegeImgUrl" />
                        <!-- <p>CAR-T疗法指定药品使用权益</p> -->
                    </div>
                </swiper-slide>
                <!-- <swiper-slide>
                    <div class="my-swiper_item">
                        <img src="@/assets/swiper2.png" />
                        <p>特药找药权益</p>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="my-swiper_item">
                        <img src="@/assets/swiper3.png" />
                        <p>临床实验申请权益</p>
                    </div>
                </swiper-slide> -->
            </swiper>
        </div>
        <div class="rightDetail-content" v-if="currentRights">
            <img :src="currentRights.privilegeDetailImgUrl" />
        </div>
        <div class="rightDetail-intro" v-if="currentRights">
            <p
                class="text-center"
            >权益详见<span v-for="(item, index) in currentRights.agreementList"
                :key="index" @click="onShow(index)">《{{ item.agreementName }}》</span></p>
        </div>
        <div class="rightDetail-step">
            <div class="reserve_box">
                <div class="title">
                    <div class="tit-con">
                        <i style="background: rgb(0, 93, 204);"></i>
                        <span>预约流程</span>
                    </div>
                </div>
                <div class="minipro">
                    <div class="stepList">
                        <p class="active_num">1</p>
                        <p class="active_name">预约告知</p>
                        <img src="@/assets/progress.png" class="img">
                    </div>
                    <div class="stepList">
                        <p class="active_num">2</p>
                        <p class="active_name">预约申请</p>
                        <img src="@/assets/progress.png" class="img">
                    </div>
                    <div class="stepList">
                        <p class="active_num">3</p>
                        <p class="active_name">预约安排</p>
                        <img src="@/assets/progress.png" class="img">
                    </div>
                    <div class="stepList">
                        <p class="active_num">4</p>
                        <p class="active_name">预约完成</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="rightDetail-footer">
            <van-button type="info" round block @click="goPage">立即预约</van-button>
        </div>
        <van-popup
            v-model="showFlag"
            round
            closeable
            position="bottom"
            close-icon="close"
            :style="{ height: '70%' }">
            <div class="text-dialog flex flex-column">
                <h2>{{ title }}</h2>
                <div class="text-content flex-1">
                    <div v-html="content"></div>
                </div>
                
            </div>
        </van-popup>
        <van-popup
        v-model="showMobile"
        round
        overlay-class="info-mask"
        closeable
        close-icon="close">
        <div class="info-mask_content">
            <h4 class="text-center">理赔申请</h4>
            <p>您可直接拨打客服电话：<a class="tel_phone" :href="'tel:' + this.privilegePhone">{{privilegePhone}}</a>申请理赔。</p>
        </div>
    </van-popup>
    </div>
</template>
<script>
import { onFetchRightsDetail } from '@/api';
export default {
    data() {
        return {
            privilegeId: '',
            personId: '',
            showFlag: false,
            showMobile: false,
            title: '',
            content: '',
            activeIndex: 0,
            swiperOption: {
                slidesPerView: 4,
                spaceBetween: 30,
                centeredSlides: true
            },
            rightsList: [],
            currentRights: null,
            isApplyInform: null,
            applyPageSkipUrl: null,
            mySwiper: null,
            privilegeType: null,
            privilegePhone: null
        }
    },
    created() {
        this.privilegeId = this.$route.query.id;
        this.personId = this.$route.query.personId;

        console.log('privilegeId', this.privilegeId);
        
        this.init();
        this.$nextTick(() => {
                this.mySwiper = this.$refs.mySwiper.$swiper;
        })
    },
    methods: {
        onHandlePrewEnd() {
            this.activeIndex = this.mySwiper.activeIndex;
            console.log('activeIndex', this.activeIndex)
            this.initData();
        },
        onHandleNextEnd() {
            this.activeIndex = this.mySwiper.activeIndex;
            console.log('activeIndex', this.activeIndex)
            this.initData();
        },
        async init() {
            try {
                const res = await onFetchRightsDetail({
                    privilegeId: this.privilegeId
                });
                console.log('res', res)
                if (res.code == 200) {
                    this.rightsList = res.data;
                    this.initData();
                }
            } catch(err) {
                console.log('err', err);
            }
        },
        initData() {
            this.currentRights = this.rightsList[this.activeIndex];
            this.isApplyInform = this.currentRights.isApplyInform;
            this.privilegeType = this.currentRights.privilegeType;
            this.privilegePhone = this.currentRights.privilegePhone;
            this.applyPageSkipUrl = this.currentRights.applyPageSkipUrl;
            let applyUrl = this.currentRights.applyUrl;
            localStorage.setItem('applyUrl', applyUrl);
            localStorage.setItem('privilegeId', this.currentRights.privilegeId);
            localStorage.setItem('personId', this.personId);
        },
        goPage() {
            if(this.privilegeType != 3) {
                if(this.isApplyInform && !this.applyPageSkipUrl) {
                    this.$router.push('/progressStep2');
                } else{
                    this.$router.push(this.applyPageSkipUrl);
                }
            }else{
                this.showMobile = true;
            }
        },
        onShow(index) {
            this.showFlag = true;
            this.title = this.currentRights.agreementList[index].agreementName;
            this.content = this.currentRights.agreementList[index].agreementContent;
        }
    }
}
</script>
<style lang="less" scoped>
.rightDetail {
    .my-swiper {
        transform: translateX(-10px);
        &_item {
            padding: 10px 0;
            width: 95px;
            text-align: center;
            opacity: .5;
            img {
                width: 43px;
                height: 43px;
            }
            p {
                font-size: 12px;
                font-weight: bold;
                color: #005dcc;
            }
        }
        .swiper-slide-active {
            .my-swiper_item {
                opacity: 1;
            }
        }
    }
    &-content {
        img {
            width: 100%;
        }
    }
    &-intro {
        // position: relative;
        background-color: #fff;
        color: #333;
        // margin-top: -20px;
        &:before {
            content: '';
            position: absolute;
            top: -20px;
            background: #fff;
            width: 100%;
            height: 20px;
            border-radius: 20px 20px 0 0;
        }
        p {
            font-size: 14px;
            span {
                color: #005dcc;
            }
        }
        img {
            width: 100%;
            padding: 0 20px;
        }
    }
    &-step {
        padding: 10px;
    }
    &-footer {
        width: 100%;
        height: 80px;
        background: #fff;
        position: fixed;
        z-index: 10;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 20px;
        .van-button--info {
            background-color: #005dcc;
        }
    }
}
.reserve_box {
    background:#fff;
    border-radius: 12px;
    box-shadow:0 2px 8px 0 rgba(0,0,0,.04);
    padding: 16px 0 80px;
    .title{
        font-size: 18px;
        font-weight:500;
        line-height:18px;
        display:flex;
        .tit-con{
            flex:1;
            display:flex;
            align-items:center;
            i {
                display:inline-block;
                vertical-align:middle;
                margin-right:10px;
                width:4px;
                height:20px;
                background:#005dcc;
                border-radius:2px;
            }
            span{
                vertical-align:middle;
                line-height: 14px;
            }
        }
    }
}
.minipro {
    display: flex;
    margin: 20px auto;
    padding-bottom: 20px;
}
.stepList{
    flex:1;
    position:relative
}
.num{
    background:#fff;
    border-radius:50%;
    color:#ccc;
    border:1px solid #ccc
}
.active_num,
.num {
    width: 30px;
    height: 30px;
    font-size: 16px;
    font-weight:500;
    text-align:center;
    line-height: 30px;
    margin:0 auto;
}
.active_num{
    background:#005dcc;
    border-radius:50%;
    color:#fff;
}
.active_name{
    color:#005dcc;
}
.active_name, .name {
    font-size: 12px;
    font-weight: 500;
    margin-top: 7px;
    text-align: center;
    line-height: 18px;
}
.name{
    color:#ccc;
}
.img{
    width: 12px;
    position:absolute;
    right:-6px;
    top: 10px;
}
.text-dialog {
    height: 100%;
    color: #333;
    h2{
        font-size: 16px;
        color: #333;
        margin: 0 auto;
        padding: 20px 0;
        text-align: center;
    }
    .text-content {
        padding: 30px 10px;
        font-size: 14px;
        line-height: 22px;
        overflow: auto;
    }
}
::v-deep .info-mask {
    background-color: rgba(0, 0, 0, .3) !important;
    &_content {
        width: 300px;
        padding: 10px 20px;
        h4 {
            margin: 0;
            padding: 10px;
            font-size: 18px;
        }
        p {
            margin-bottom: 10px;
            font-size: 14px;
            text-align: center;
        }
        .qrcode {
            width: 150px;
            height: 150px;
            margin: 10px auto;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}
</style>