<template>
    <div class="rights-page">
        <p class="rights-page_title" v-if="payShow">
            <van-icon name="checked" />
        </p>
        <h2>{{payResult}}</h2>
        <van-overlay :show="loading" >
            <van-loading size="40" vertical class="loadingDiv">支付结果查询中...</van-loading>
        </van-overlay>
    </div>
    
</template>
<script>
import { Toast, Dialog, Loading, Overlay } from 'vant';
import { selectPayState } from '@/api';
export default {
    name: 'rights',
    data() {
        return {
            payNo: '',
            loading: true,
            payResult: '',
            payShow: false
        }
    },
    created() {
        this.payNo = this.$route.query.payNo;
        this.onHandlePay();
    },
    methods: {
        goPage(page) {
            this.$router.push(page);
        },
        goPersonal() {
            this.$router.push('/personal');
        },
        async onHandlePay(){
            const res = await selectPayState({
                payNo:this.payNo
            });
            if (res.code == 200) {
                if(res.data.tradeState == 'SUCCESS'){
                    this.payResult = '支付成功';
                    this.payShow = true;
                    this.loading = false;
                } else{
                    this.payResult = '微信支付失败';
                    this.$toast("微信支付失败");
                    this.loading = false;
                }
            } else {
                his.payResult = '微信支付失败';
                this.$toast("微信支付失败");
                this.loading = false;
            }
        }
    }
}
</script>
<style scoped lang="less">
.rights-page {
    text-align: center;
    padding: 0 40px;
    color: #333;
    &_title {
        color: #4e8fe0;
        font-size: 50px;
        margin: 70px 0 40px;
    }
    h2 {
        font-size: 20px;
        margin-bottom: 40px;
    }
}
.loadingDiv {
    position: absolute;
    left: 35%;
    top: 40%;
}
</style>