<template>
    <div class="serverPdf">
    	<div>
			<div class="divText"><img src="https://more-health.oss-cn-beijing.aliyuncs.com/image/ec4a0557-5a60-44c8-add4-8b09a12ea2af.jpg"></div>
			<div class="divText">甲方：{{personName}}</div>
			<div class="divText">身份证号：{{idCardNo}}</div>
			<div class="divText">服务卡号：{{activationCardNumber}}</div>
			<div class="divText">电话：{{personPhone}}</div>
			<div class="divText" style="height: 15px;"></div>
			<div class="divText">乙方：上海壹树医疗科技有限公司</div>
			<div class="divText">地址：中国(上海)自由贸易试验区纳贤路 800 号 1 幛  B 座 9 楼 902-2</div>
			<div class="divText"></div>
		</div>
		<img @click="preview(0)" src="https://more-health.oss-cn-beijing.aliyuncs.com/image/b4ea09e5-1ff7-48fc-9ca7-5cc59f93917b.jpg">
        <img @click="preview(1)" src="https://morehealthy.oss-cn-beijing.aliyuncs.com/teyao1.0/2.png">
        <img @click="preview(2)" src="https://morehealthy.oss-cn-beijing.aliyuncs.com/teyao1.0/3.png">
        <img @click="preview(3)" src="https://morehealthy.oss-cn-beijing.aliyuncs.com/teyao1.0/4.png">
        <img @click="preview(4)" src="https://morehealthy.oss-cn-beijing.aliyuncs.com/teyao1.0/5.png">
        <img @click="preview(5)" src="https://morehealthy.oss-cn-beijing.aliyuncs.com/teyao1.0/6.png">
        <img @click="preview(6)" src="https://morehealthy.oss-cn-beijing.aliyuncs.com/teyao1.0/7.png">
        <img @click="preview(7)" src="https://morehealthy.oss-cn-beijing.aliyuncs.com/teyao1.0/8.png">
        <img @click="preview(8)" src="https://morehealthy.oss-cn-beijing.aliyuncs.com/teyao1.0/9.png">
        <img @click="preview(9)" src="https://morehealthy.oss-cn-beijing.aliyuncs.com/teyao1.0/10.png">
        <img @click="preview(10)" src="https://morehealthy.oss-cn-beijing.aliyuncs.com/teyao1.0/11.png">
        <img @click="preview(11)" src="https://morehealthy.oss-cn-beijing.aliyuncs.com/teyao1.0/12.png">
        <img @click="preview(12)" src="https://morehealthy.oss-cn-beijing.aliyuncs.com/teyao1.0/13.png">
        <img @click="preview(13)" src="https://morehealthy.oss-cn-beijing.aliyuncs.com/teyao1.0/14.png">
        <img @click="preview(14)" src="https://morehealthy.oss-cn-beijing.aliyuncs.com/teyao1.0/15.png">
        <img @click="preview(15)" src="https://morehealthy.oss-cn-beijing.aliyuncs.com/teyao1.0/16.png">
        <img @click="preview(16)" src="https://morehealthy.oss-cn-beijing.aliyuncs.com/teyao1.0/17.png">
        <img @click="preview(17)" src="https://morehealthy.oss-cn-beijing.aliyuncs.com/teyao1.0/18.png">

    </div>
</template>

<script>
import { contents } from './util';
import { ImagePreview } from 'vant';
import { weixinInit } from '@/utils/wechatUtil.js';
import { selUserPrivilegeDetail  } from '@/api';
export default {
    data() {
        return {
           personName: '',
           idCardNo: '',
           activationCardNumber: '',
           personPhone: '',
           personPrivilegeId: '',
        }
    },
    created() {
        this.personPrivilegeId = this.$route.query.personPrivilegeId;
        this.init();
    },
    methods: {
        async preview(index) {
            ImagePreview({
            images:[
                'https://more-health.oss-cn-beijing.aliyuncs.com/image/b4ea09e5-1ff7-48fc-9ca7-5cc59f93917b.jpg',
                'https://morehealthy.oss-cn-beijing.aliyuncs.com/teyao1.0/2.png',
                'https://morehealthy.oss-cn-beijing.aliyuncs.com/teyao1.0/3.png',
                'https://morehealthy.oss-cn-beijing.aliyuncs.com/teyao1.0/4.png',
                'https://morehealthy.oss-cn-beijing.aliyuncs.com/teyao1.0/5.png',
                'https://morehealthy.oss-cn-beijing.aliyuncs.com/teyao1.0/6.png',
                'https://morehealthy.oss-cn-beijing.aliyuncs.com/teyao1.0/7.png',
                'https://morehealthy.oss-cn-beijing.aliyuncs.com/teyao1.0/8.png',
                'https://morehealthy.oss-cn-beijing.aliyuncs.com/teyao1.0/9.png',
                'https://morehealthy.oss-cn-beijing.aliyuncs.com/teyao1.0/10.png',
                'https://morehealthy.oss-cn-beijing.aliyuncs.com/teyao1.0/11.png',
                'https://morehealthy.oss-cn-beijing.aliyuncs.com/teyao1.0/12.png',
                'https://morehealthy.oss-cn-beijing.aliyuncs.com/teyao1.0/13.png',
                'https://morehealthy.oss-cn-beijing.aliyuncs.com/teyao1.0/14.png',
                'https://morehealthy.oss-cn-beijing.aliyuncs.com/teyao1.0/15.png',
                'https://morehealthy.oss-cn-beijing.aliyuncs.com/teyao1.0/16.png',
                'https://morehealthy.oss-cn-beijing.aliyuncs.com/teyao1.0/17.png',
                'https://morehealthy.oss-cn-beijing.aliyuncs.com/teyao1.0/18.png'
            ],
            startPosition: index,
            closeable: true,
        });
        },
        async init() {
            try {
                const res = await selUserPrivilegeDetail({
                    personPrivilegeId: this.personPrivilegeId
                });
                if (res.code == 200) {
                    this.personName = res.data[0].personName;
                    this.idCardNo = res.data[0].credentialsNumber;
                    this.activationCardNumber = res.data[0].activationCardNumber;
                    this.personPhone = res.data[0].personPhone;
                } else if (res.code == 2014) {
                    // 无权益
                    this.rightsVisable = false;
                }
            } catch(err) {
                console.log('err', err);
            }
        }
    }
}
</script>
<style scoped lang="less">
.serverPdf {
    width: 100%;
    margin: 0;
    img {
        width: 100%;
    }
}
.divText {
    margin-left: 25px;
    width: 330px;
    font-size: 10px;
    line-height: 20px;
}
</style>