<template>
    <div class="rights-page">
        <p class="rights-page_title">
            <van-icon name="checked" />
        </p>
        <h2>预约申请提交成功</h2>
        <div class="info">
            <p>您的信息已提交，</p>
            <p>我们会尽快联系您，请您耐心等待</p>
        </div>
        <p>
            <van-button block round type="info" size="normal" @click="goPage">查看预约信息</van-button>
        </p>
        <p class="margin-top-sm">
            <van-button block round plain type="info" size="normal" @click="goPersonal">返回个人中心</van-button>
        </p>
    </div>
</template>
<script>
export default {
    name: 'rights',
    data() {
        return {

        }
    },
    created() {
        this.id = this.$route.query.id;
    },
    methods: {
        goPage() {
            this.$router.push(`/appointmentDetail?id=${this.id}`);
        },
        goPersonal() {
            this.$router.push('/personal');
        }
    }
}
</script>
<style scoped lang="less">
.rights-page {
    text-align: center;
    padding: 0 40px;
    color: #333;
    // img {
    //     width: 50px;
    //     height: 50px;
    // }
    &_title {
        color: #4e8fe0;
        font-size: 50px;
        margin: 70px 0 40px;
    }
    h2 {
        font-size: 20px;
        margin-bottom: 40px;
    }
    .info {
        font-size: 14px;
        color: #999;
        margin-bottom: 40px;
    }
}
</style>