<template>
    <div class="rights-page">
        <p class="rights-page_title">
            <van-icon name="checked" />
        </p>
        <h2>建档登记成功</h2>
    </div>
    
</template>
<script>
import { Toast, Dialog, Loading, Overlay } from 'vant';
export default {
    name: 'rights',
    data() {
        return {
            loading: true,
            payResult: '',
            payShow: false
        }
    },
    created() {
        
    },
    methods: {
        
    }
}
</script>
<style scoped lang="less">
.rights-page {
    text-align: center;
    padding: 0 40px;
    color: #333;
    &_title {
        color: #4e8fe0;
        font-size: 50px;
        margin: 70px 0 40px;
    }
    h2 {
        font-size: 20px;
        margin-bottom: 40px;
    }
}
.loadingDiv {
    position: absolute;
    left: 35%;
    top: 40%;
}
</style>