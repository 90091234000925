<template>
    <div class="right-person">
        <div class="bg">
            <div >
                <div class="banner">
                    <img v-if="publicType == 2" src="@/assets/rights_banner.jpg" />
                </div>
                <div class="header">
                    <img src="@/assets/rights-title.jpg" />
                </div>
                <!-- 可循环 -->
                <div class="right-person_item">
                    <div class="right-person_item__content">
                        <ul>
                            <li v-for="(listItem, listIndex) in privilegeList" :key="listIndex" :class="[{'unclick': [2,3,4].includes(listItem.privilegeState)}]">
                                
                                <div class="flex">
                                    <img :src="listItem.privilegeImgUrl" />
                                    <div class="flex-1">
                                        <div class="flex flex-between">
                                            <p class="title">{{ listItem.privilegeName }}</p>
                                            <p class="padding-left-sm text-right">
                                                <van-button v-if="listItem.privilegeState == 1" class="btn-mini" size="mini" round type="info" @click="onHandlePrivew()">立即预约</van-button>
                                            </p>
                                        </div>
                                        <div class="desc-info">
                                            <dl v-for="(descItem, descIndex) in listItem.descList" :key="descIndex">
                                                <dt class="flex flex-column-center" v-if="descItem.privilegeDesc">
                                                    <img class="dot" src="@/assets/dot.png" />
                                                    <span>{{ descItem.privilegeDesc }}</span>
                                                </dt>
                                                <dd v-if="descItem.privilegeListDesc.length > 0">
                                                    <span v-for="dItem in descItem.privilegeListDesc" :key="dItem">{{dItem}}</span>
                                                </dd>
                                            </dl>
                                        </div>
                                        <!-- <p class="person-date">有效期：{{ listItem.privilegeExpire }}</p> -->
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Toast } from 'vant';
import { onFetchProductInfo } from '@/api';
import { weixinInit } from '@/utils/wechatUtil.js';
export default {
    name: 'productShow',
    data() {
        return {
            loading: true, // loading页面
            show: false,
            rightsVisable: false,
            privilegeList: [],
            publicType: null,
            toastShow: false, // 是否展示药品种类弹窗
            personItem: null, // 受益人信息
            personShow: false, // 添加受益人
            personName: '',
            credentialsNumber: '',
            relationType: '2',
            personId: '',
            productCode: '',
            drugList: [], // 药品列表
            weixinShare: {
                title: '特药权益介绍',
                desc: '多重权益覆盖，全流程专业服务',
                url: '',
                imgUrl: 'https://more-health.oss-cn-beijing.aliyuncs.com/image/fc3e90ed-f0b0-4755-aab0-e860ce0bd591.jpg',
                publicType: ''
            }
        }
    },
    created() {
        this.publicType = localStorage.getItem('publicType');
        this.productCode = this.$route.query.productCode || 'rainbow3';
        this.weixinShare.url = window.location.href.split('#')[0];
        this.weixinShare.publicType = this.publicType;
        this.wxConfigInit();
        this.init();
    },
    methods: {
        async wxConfigInit() {
            await weixinInit(this.weixinShare);
        },
        showPopup() {
            this.toastShow = true;
        },
        onShow() {
            this.show = true;
        },
        // 获取权益
        async init() {
            this.loading = true;
            try {
                const res = await onFetchProductInfo({
                    productCode: this.productCode
                });
                if (res.code == 200) {
                    this.privilegeList = res.data.privilegeList;
                    this.rightsVisable = true;
                } else if (res.code == 2014) {
                    // 无权益
                    this.rightsVisable = false;
                }
            } catch(err) {
                console.log('err', err);
            }
            this.loading = false;
        },
        // 使用权益
        onHandlePrivew() {
            this.$router.push(`/rights?publicType=${this.publicType}&productCode=${this.productCode}`);
        }
    }
}
</script>
<style scoped lang="less">
::v-deep .van-radio-group--horizontal {
    display: flex;
    .van-radio--horizontal {
        flex: 1;
        font-size: 12px;
    }
}
.banner {
    width: 100%;
    margin: 0;
    img {
        width: 100%;
    }
}
.table-toast {
    position: relative;
    width: 310px;
    &_max {
        max-height: 300px;
        overflow-y: auto;
    }
    table {
        width: 100%;
        border-collapse:collapse;
        border:none;
        position: relative;
        &.absolute {
            tbody {
                display: none;
            }
        }
        td,
        th {
            padding: 5px;
            font-size: 12px;
            font-weight: normal;
        }
        th {
            padding: 10px 5px;
        }
        thead {
            background: #94d3f4;
            font-size: 16px;
            font-weight: 500;
        }
        tbody {
            tr {
                &:nth-child(even) {
                    background: #f2f2f2;
                }
            }
        }
    }
}
.add-person {
    padding: 20px;
    width: 300px;
}
.van-cell::after {
    border-bottom: 0;
}
.btn-options {
    button {
        margin: 0 10px;
    }
}

::v-deep .info-mask {
    background-color: rgba(0, 0, 0, .3) !important;
    &_content {
        width: 280px;
        padding: 10px 20px;
        h4 {
            margin: 0;
            padding: 10px;
            font-size: 18px;
        }
        p {
            margin-bottom: 10px;
            font-size: 14px;
            display: flex;
            justify-content: space-between;
        }
    }
}
.right-person{
    color: #333;
    // background: #f5f5f5;
    background: #fff;
    .bg {
        // padding: 10px;
        .header {
            img {
                width: 50%;
                display: block;
                font-size: 0;
                margin: 20px auto 10px;
            }
        }
    }
    .notice {
        background: rgba(252, 137, 64, 0.03);
        border-radius: 5px;
        padding: 5px;
        color: #FC8940;
        font-size: 12px;
        margin: 10px auto 0;
        display: flex;
        align-items: center;
        .remind {
            background: #FC8940;
            padding: 2px 5px;
            color: #fff;
            font-size: 10px;
            margin-right: 5px;
            border-radius: 2px;
            text-align: center;
        }
    }
    h2 {
        margin: 10px 0;
        font-size: 18px;
        position: relative;
        padding: 0 10px;
        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 10%;
            width: 4px;
            height: 80%;
            background: #1989fa;
        }
    }
    &_item {
        margin-bottom: 20px;
        &__title {
            background: #fff;
            border-radius: 10px 10px 0 0;
            padding: 10px;
            font-size: 14px;
            margin-bottom: 10px;
            h4 {
                font-size: 16px;
            }
            .person-info {
                margin-top: 10px;
                img {
                    width: 17px;
                    height: 17px;
                }
            }
        }
        &__content {
            ul {
                padding: 10px;
                li {
                    position: relative;
                    padding: 25px 5px 5px;
                    font-size: 12px;
                    color: grey;
                    margin-bottom: 10px;
                    border-radius: 10px;
                    background: #fff;
                    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
                    &.unclick {
                        filter: grayscale(1);
                        opacity: 0.6;
                    }
                    .item-tag {
                        position: absolute;
                        left: 0;
                        top: 0;
                        border-radius: 10px 0 10px 0;
                        overflow: hidden;
                        font-size: 12px;
                        padding: 3px 8px;
                        color: #fff;
                        background-color: #1989fa;
                    }
                    img {
                        // margin-top: 10px;
                        width: 70px;
                        height: 70px;
                    }
                    .title {
                        color: #333;
                        font-size: 18px;
                        font-weight: bold;
                        margin-bottom: 10px;
                        flex: 1;
                    }
                    .btn-mini {
                        padding-left: 10px;
                        padding-right: 10px;
                    }
                    .desc-info {
                        dl {
                            dt, 
                            dd {
                                margin-bottom: 5px;
                            }
                            dt {
                                font-size: 16px;
                                color: #0b84ff;
                                img {
                                    margin-top: 0;
                                    margin-right: 5px;
                                    width: 5px;
                                    height: 5px;
                                }
                            }
                            dd {
                                padding: 3px 5px;
                                font-size: 14px;
                                line-height: 20px;
                                // color: #005DCC;
                                // background: #F3F8FF;
                                span {
                                    &::after {
                                        content: '、';
                                    }
                                    &:last-child {
                                        &::after {
                                            content: '';
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &:last-child {
                        border-bottom: none;
                    }
                }
            }
        }
    }
    .right-empty {
        padding: 40px;
        .empty-bottom-text {
            width: 200px;
            text-align: center;
            font-size: 14px;
            .active {
                color: #1989fa;
            }
        }
    }
}
</style>