<template>
    <div class="login">
        <div class="logo">
            <img src="@/assets/login-logo.png" />
        </div>
        <van-form @submit="onSubmit">
            <p class="title">手机号</p>
                <van-field
                    v-model="mobile"
                    center
                    clearable
                    placeholder="请输入手机号"
                     :rules="[{ required: true, message: '请输入手机号' }]"
                    >
                    <template #left-icon class="flex">
                        <img class="safe" src="@/assets/mobile.png" />
                    </template>
                </van-field>
            <p class="title">验证码</p>
            <van-field
                v-model="sms"
                center
                clearable
                maxlength="6"
                placeholder="请输入验证码"
                :rules="[{ required: true, message: '请输入验证码' }]"
                >
                <template #left-icon class="flex">
                    <img class="safe" src="@/assets/safe.png" />
                </template>
                <template #button>
                    <span class="valid-text">获取验证码</span>
                </template>
            </van-field>
            <div>
                <van-button class="login-btn" round block type="info" native-type="submit">登录</van-button>
            </div>
            <div class="agreement flex">
                <van-checkbox v-model="checked"></van-checkbox>
                <div class="flex-1 padding-left-sm">
                    <span>我已阅读并同意</span>
                    <span class="text-link">《关爱付隐私协议》</span>
                    <span class="text-link">《关爱付平台服务协议》</span>
                </div>
            </div>
        </van-form>
       
    </div>
</template>
<script>
export default {
    data() {
        return {
            mobile: '',
            sms: '',
            checked: false
        }
    }
}
</script>
<style scoped>
.login {
    padding: 0 40px;
}
.login .logo {
    width: 100%;
    margin: 40px auto;
    padding: 0 20px;
}
.login .logo img {
    width: 100%;
}
.login .title {
    font-size: 14px;
    margin-bottom: 15px;
    font-weight: 500;
}
.van-cell {
    position: relative;
    border: 0.5px solid #e4e4e4;
    border-radius: 30px;
    margin-bottom: 20px;
    line-height: 25px;
}
::v-deep .van-field__left-icon {
    display: flex;
    align-items: center;
}
.safe {
    width: 18px;
}
.valid-text {
    color: rgb(0, 93, 204);
    font-size: 14px;
}
::v-deep .van-field__control {
    color: #666 !important;
}
::v-deep .van-field__error-message {
    position: absolute;
    line-height: 12px;
    bottom: -8px;
}
.login-btn {
    margin: 30px 0 20px;
}
.agreement {
    margin-top: 20px;
    align-items: flex-start;
    font-size: 12px;
    color: #1d1d1d;
}
.agreement .text-link {
    color:rgb(0, 93, 204);
}
</style>