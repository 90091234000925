<template>
    <div class="progress-page">
        <div class="rightDetail-step">
            <div class="reserve_box">
                <div class="minipro">
                    <div class="stepList">
                        <p class="num active_num">1</p>
                        <p class="name active_name">预约告知</p>
                        <img src="@/assets/progress.png" class="img">
                    </div>
                    <div class="stepList">
                        <p class="num active_num">2</p>
                        <p class="name active_name">预约申请</p>
                        <img src="@/assets/progress1.png" class="img">
                    </div>
                    <div class="stepList">
                        <p class="num">3</p>
                        <p class="name">预约安排</p>
                        <img src="@/assets/progress1.png" class="img">
                    </div>
                    <div class="stepList">
                        <p class="num">4</p>
                        <p class="name">预约完成</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="content">
            <div class="box margin-bottom-sm">
                <h4 class="box-title">
                    <div class="box-title-con">
                        <i style="background: rgb(0, 93, 204);"></i>
                        <span>预约权益</span>
                    </div>
                </h4>
                
                <div class="box-select flex flex-column-center flex-between" @click="showPrivilege = true">
                    <p>{{privilegeName}}</p>
                    <van-icon name="arrow-down" />
                </div>
                

                <!-- <van-action-sheet
                    v-model="show"
                    title="请选择预约的权益"
                    safe-area-inset-bottom
                >
                <div class="content-radio">
                    <van-radio-group v-model="privilegeId">
                        <van-cell-group>
                            <van-cell v-for="(item, index) in privilegeList" :key="index" :title="item.privilegeName" clickable @click="privilegeId = item.privilegeId+''">
                                <template #right-icon>
                                    <van-radio :name='`${item.privilegeId}`'>
                                        <template #icon="props">
                                        <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon" />
                                        </template>
                                    </van-radio>
                                </template>
                            </van-cell>
                        </van-cell-group>
                    </van-radio-group>
                </div>
                <div class="text-center padding-confirm">
                    <van-button type="info" round block @click="onHandleSelectRights">确定</van-button>
                </div>
                </van-action-sheet> -->
                <div class="box-explain">
                    *当前权益剩余 {{ surplusCount }} 次
                </div>
            </div>
            <div class="box margin-bottom-sm">
                <h4 class="box-title">
                    <div class="box-title-con">
                        <i style="background: rgb(0, 93, 204);"></i>
                        <span>权益人信息</span>
                    </div>
                </h4>
                <van-form>
                    <!-- <van-field
                        v-model="personName"
                        error
                        required
                        readonly
                        name="姓名"
                        label="姓名"
                        placeholder="用户名"
                        :rules="[{ required: true, message: '请填写用户名' }]"
                    /> -->

                    <van-cell-group required>
                        <van-field required  v-model="personName" label="姓名"/>
                    </van-cell-group>
                    <van-cell-group required>
                        <van-field required readonly v-model="cardType" label="证件类型" />
                    </van-cell-group>
                    <van-cell-group required>
                        <van-field required  v-model="credentialsNumber" label="证件号码" placeholder="请输入用户名" />
                    </van-cell-group>
                    
                    <!--<van-cell required title="出生日期" :value="birthday" @click="showBirthday = true" is-link /> -->
                   
                    <!-- 出生日期 -->
                    <!-- <van-popup v-model="showBirthday" position="bottom">
                        <van-datetime-picker
                            v-model="currentDate"
                            type="date"
                            title="选择年月日"
                            :min-date="minDate"
                            :max-date="maxDate"
                            @confirm="onBirthdaySelect"
                            @cancel="showBirthday = false"
                        />
                    </van-popup> -->
                    <van-cell-group required>
                        <van-field required  v-model="personPhone" label="手机号" placeholder="请输入手机号" />
                    </van-cell-group>
                    <van-cell required title="国籍" :value="nationalityValue" @click="showNationality = true" is-link />
                    
                    <van-popup v-model="showNationality" position="bottom">
                        <van-picker
                            show-toolbar
                            :columns="columnsNationality"
                            @confirm="onConfirmNationality"
                            @cancel="showNationality = false"
                        />
                    </van-popup>
                    <van-cell-group  v-show="showNationalityOther">
                        <van-field required v-model="nationalitySelectValue" label="国籍" placeholder="请输入国籍" />
                    </van-cell-group>
                    
                    <van-cell required title="民族" :value="nationValue" @click="showNation = true" is-link />
                    
                    <van-popup v-model="showNation" position="bottom">
                        <van-picker
                            show-toolbar
                            :columns="columnsNation"
                            @confirm="onConfirmNation"
                            @cancel="showNation = false"
                        />
                    </van-popup>

                    <div class="box-explain" >如注射人未成年,请填写监护人信息。(成年请忽略)</div>
                    <van-cell-group required>
                        <van-field  v-model="guardianName" label="监护人姓名" placeholder="请输入监护人姓名" />
                    </van-cell-group>
                    <van-cell-group required>
                        <van-field  v-model="guardianCardNumber" label="监护人身份证" placeholder="请输入监护人身份证" />
                    </van-cell-group>
                </van-form>
            </div>
            <div class="box margin-bottom-sm">
                <h4 class="box-title">
                    <div class="box-title-con">
                        <i style="background: rgb(0, 93, 204);"></i>
                        <span>预约信息</span>
                    </div>
                </h4>
                <van-form>
                    <van-cell required title="预约医院" :value="hospitalValue" @click="showHospital = true" is-link />
                    
                    <van-popup v-model="showHospital" position="bottom">
                        <van-picker
                            show-toolbar
                            :columns="columnsHospital"
                            @confirm="onConfirmHospital"
                            @cancel="showHospital = false"
                        />
                    </van-popup>
                    <div class="box-explain" >预约时间：（每周3，4，5，7可以预约）</div>
                    <div class="box-explain"></div>
                    <div class="box-explain" >客户到医院后，凭借预约短信，需要正常履行医院的现场挂号(医院保健科)和缴费等手续。</div>
                    <van-cell required title="预约时间" :value="docDate" @click="showDocDate = true" is-link />
                   
                    <van-calendar 
                        v-model="showDocDate" 
                        @confirm="onBirthdaySelect"
                        color="#005dcc"
                        :show-confirm="false"
                        :style="{ height: '600px' }"
                        first-day-of-week="1"
                        title="预约时间选择"
                        type="single"
                        :formatter="formatter"
                        />
                    
                    <!-- <van-popup v-model="showDocDate" position="bottom">
                        <van-datetime-picker
                            v-model="applyDate"
                            type="date"
                            title="选择年月日"
                            :filter="filter"
                            :min-date="minDocDate"
                            :max-date="maxDocDate"
                            @confirm="onBirthdaySelect"
                            @cancel="showDocDate = false"
                        />
                    </van-popup> -->
                    <van-cell required title="预约时间段" :value="docDateSlot" @click="showPicker = true" is-link />
                    
                    <van-popup v-model="showPicker" position="bottom">
                        <van-picker
                            show-toolbar
                            :columns="columns"
                            @confirm="onConfirm"
                            @cancel="showPicker = false"
                        />
                    </van-popup>
                    <van-popup v-model="showPrivilege" position="bottom">
                        <van-picker
                        show-toolbar
                        :columns="privilegeColumns"
                        @confirm="onConfirmPrivilege"
                        @cancel="onCancelPrivilege"
                    />
                    
                </van-popup>
                </van-form>
            </div>
        </div>
        <div class="progress-page_footer fixed">
            <div class="btn-options">
                <van-button type="info" :disabled="btnDisabled" round block @click="goPage">下一步</van-button>
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment';
moment().format();
const cardTypeFilter = {
    1: '身份证',
    2: '护照',
    3: '港澳内地通行证',
    4: '台胞证'
}
const sexFilter = {
    0: '男',
    1: '女'
}

import { areaList } from '@vant/area-data';
import { onFetchRightsUsedInfo, onUploadImage } from '@/api';
import { ImagePreview, Toast, Calendar } from 'vant';
export default {
    data() {
        return {
            activeIcon: require('@/assets/checked.png'),
            inactiveIcon: require('@/assets/check-none.png'),
            show: false,
            showPrivilege: false,
            showHospital: false,
            showNationality: false,
            showNationalityOther: false,
            showNation: false,
            areaList,
            cityVisible: false, // city flag
            showBirthday: false, // birthday flag
            minDate: new Date(1940, 0, 1),
            maxDate: new Date(2099, 11, 31),
            currentDate: new Date(),
            showDocDate: false, // 就诊时间 
            docDate: null, // 就诊时间 
            docDateSlot: null,//就诊时间段
            hospitalValue: '北京爱育华妇儿医院',
            nationalityValue: '中国',
            nationalitySelectValue: '中国',
            nationValue: '汉族',
            minDocDate: moment(new Date()).add(7, 'd')._d,
            maxDocDate: moment(new Date()).add(1, 'y')._d,
            birthday: '', // 出生日期
            personName: '', // 就诊人
            credentialsNumber: '', // 身份证号
            cardType: '',
            phoneFlag: true, // 是否可以输入手机号
            personPhone: '', // 手机号
            privielgeImgUrl: '', // 权益图片
            privilegeName: '九价苗', // 权益名
            privilegeId: '',
            surplusCount: 0, // 权益剩余次数
            credentialsFrontUrl: [], // 身份证正面
            credentialsFrontUrlArray: [], // 身份证正面传的值
            credentialsBackUrl: [], // 身份证反面
            credentialsBackUrlArray: [], // 身份证反面传过去的值
            guardianName: '',//监护人姓名
            guardianCardNumber: '',//监护人身份证号
            height: "",
            weight: "",
            applyDate: "",
            applyDesc: "",
            regionCode: "",
            cityValue: '', // 所在城市
            caseList: [],
            diagnosticReport: [],
            otherList: [],
            caseListArray: [],
            diagnosticReportArray: [],
            otherListArray: [],
            username: '',
            sex: '',
            columns: ['上午(8:30-11:30)','下午(13:30-16:00)'],
            privilegeColumns: ['九价苗'],
            columnsHospital: ['北京爱育华妇儿医院'],
            columnsNationality: ['中国','其他'],
            columnsNation: ['汉族','满族','蒙古族','回族','藏族','维吾尔族','苗族','彝族','壮族','布依族','侗族','瑶族','白族','土家族','哈尼族','哈萨克族','傣族','黎族','傈僳族','佤族','畲族','高山族','拉祜族','水族','东乡族','纳西族','景颇族','柯尔克孜族','土族','达斡尔族','仫佬族','羌族','布朗族','撒拉族','毛南族','仡佬族','锡伯族','阿昌族','普米族','朝鲜族','塔吉克族','怒族','乌孜别克族','俄罗斯族','鄂温克族','德昂族','保安族','裕固族','京族','塔塔尔族','独龙族','鄂伦春族','赫哲族','门巴族','珞巴族','基诺族'],
            showPicker: false,
            btnDisabled: false, // 是否可以点击下一步
            uploader1: [],
            uploader2: [],
            uploader3: [],
            uploader4: [],
            uploader5: [],
            uploader6: [],
            uploader7: [],
            uploader8: [],
            uploader9: [],
            holidayArray: ['2023-1-4','2023-1-5','2023-1-6','2023-1-8','2023-1-11','2023-1-12','2023-1-13','2023-1-15','2023-1-18','2023-1-19','2023-1-20','2022-12-31','2023-1-1','2023-1-2','2023-01-1','2023-01-2','2023-01-21','2023-1-21','2023-01-22','2023-1-22','2023-01-23','2023-1-23','2023-01-24','2023-1-24','2023-01-25','2023-1-25','2023-01-26','2023-1-26','2023-01-27','2023-1-27','2023-04-5','2023-4-5','2023-04-29','2023-4-29','2023-04-30','2023-4-30','2023-05-1','2023-5-1','2023-05-2','2023-5-2','2023-05-3','2023-5-3','2023-06-22','2023-6-22','2023-06-23','2023-6-23','2023-06-24','2023-6-24'],
            privilegeList: [], // 权益列表
        }
    },
    created() {
        this.privilegeId = localStorage.getItem('privilegeId');
        this.personId = localStorage.getItem('personId');
        this.init();
    },
    methods: {
        // 选择权益
        onHandleSelectRights() {
            this.onSelectRights();
            this.show = false;
        },
        // 选择权益
        onSelectRights() {
            console.log('this.privilegeList', this.privilegeList)
            console.log('this.privilegeId', this.privilegeId)
            this.privilegeList.forEach(v => {
                if (v.privilegeId == this.privilegeId) {
                    this.privielgeImgUrl = v.privielgeImgUrl;
                    this.surplusCount = v.surplusCount;
                }
            })
        },
        async init() {
            try {
                let params = {
                    privilegeId: this.privilegeId
                }

                if (this.personId) {
                    Object.assign(params, {
                        personId: this.personId
                    })
                }
                const res = await onFetchRightsUsedInfo(params);
                if(res.code == 200) {
                    console.log('res', res);
                    const { data } = res;
                    this.personName = data.personName;
                    this.privilegeList = data.privilegeList;
                    this.credentialsNumber = data.credentialsNumber;
                    this.cardType = cardTypeFilter[data.credentialsType];
                    this.sex = sexFilter[data.sexInt];
                    this.birthday = data.birthday;
                    this.personPhone = data.personPhone;
                    this.phoneFlag = this.personPhone ? true: false;
                    this.regionCode = data.regionCode;
                    // 选择权益
                    this.onSelectRights();
                   
                }
            } catch(err) {
                console.log('err', err);
            }
        },
        // 选择城市
        onSelectCity(list) {
            console.log('list', list);
            this.cityVisible = false;

            this.cityValue = '';
            list.forEach(v => {
                this.cityValue += v.name;
            });
            console.log('list[list.length - 1]', list[list.length - 1])
            this.regionCode = list.reverse()[0].code;
            console.log('this.regionCode', this.regionCode)
        },
        // 取消选择城市
        onCancleSelectCity() {
            this.cityVisible = false;
        },
        onBirthdaySelect(date) {
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let day = date.getDate();
            this.docDate = `${year}-${month}-${day}`;
            this.applyDate = this.docDate;
            this.showDocDate = false;
        },
        addDate(date, days) {
            if (days == undefined || days == '') {
                days = 1;
            }
            var date = new Date(date);
            if(days != 0){
                date.setDate(date.getDate() + days);
            }
            var month = date.getMonth() + 1; //月份从0开始所以需要+1
            var day = date.getDate();
            return date.getFullYear() + '-' + month + '-' + day;
        },
        formatter(day) {
            //3是周二
            let week = day.date.getDay() + 1;
            
            let year = day.date.getFullYear();
            let month = day.date.getMonth() + 1;
            let dayNow = day.date.getDate();
            let formatDay = `${year}-${month}-${dayNow}`;
            let weekArray = [];
            var now = new Date();
            var weekNow = now.getDay() + 1;
            //如果等于周二
            if(weekNow == 3){
                weekArray.push(now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate());
                weekArray.push(this.addDate(now, 1));
                weekArray.push(this.addDate(now, 2));
                weekArray.push(this.addDate(now, 3));
                weekArray.push(this.addDate(now, 5));
            }
            //如果等于周三
            if(weekNow == 4){
                weekArray.push(now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate());
                weekArray.push(this.addDate(now, 1));
                weekArray.push(this.addDate(now, 2));
                weekArray.push(this.addDate(now, 4));
            }
            //等于周四
            if(weekNow == 5){
                weekArray.push(now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate());
                weekArray.push(this.addDate(now, 1));
                weekArray.push(this.addDate(now, 3));
            }
            //等于周五
            if(weekNow == 6){
                weekArray.push(now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate());
                weekArray.push(this.addDate(now, 2));
            }
            //等于周六
            if(weekNow == 7){
                weekArray.push(now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate());
                weekArray.push(this.addDate(now, 1));
            }
            //等于周日
            if(weekNow == 1){
                weekArray.push(now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate());
            }
            if(week == 2 || week == 3 || week == 7 || this.holidayArray.includes(formatDay) || month == 12 || month == 10 || weekArray.includes(formatDay)){
                day.type = 'disabled'
            }
            return day
        },
        // 身份证预览
        previewImg(type) {
            const imageJson = {
                1: require('@/assets/idCard-front.png'),
                2: require('@/assets/idCard-back.png')
            }
            ImagePreview([imageJson[type]]);
        },
        beforeDelete(file, name) {
            console.log('before delete', name);
            this[name.name] = [];
        },
        // 上传
        async afterRead(file, obj) {
            const { name } = obj;
            // 此时可以自行将文件上传至服务器
            console.log('name', name);
            console.log('file', file)
            try {
                const res = await onUploadImage({
                    file: file.file
                });
                console.log('res', res);
                if(res.code == 200) {
                    const listType = ['caseListArray', 'diagnosticReportArray', 'otherListArray'];
                    console.log('listType.includes(name)', listType.includes(name))
                    if (listType.includes(name)) {
                        this[`${name}`].push(res.data.imgUrl);
                    } else {
                        this[`${name}`] = res.data.imgUrl;
                    }
                    console.log('this', this);
                }
            } catch(err) {
                console.log('err', err);
            }

        },
        // 性别
        onConfirm(value) {
            this.docDateSlot = value;
            this.showPicker = false;
        },
        onConfirmPrivilege(value) {
            this.privilegeName = value;
            this.showPrivilege = false;
        },
        onConfirmHospital(value) {
            this.hospitalValue = value;
            this.showHospital = false;
        },
        onConfirmNationality(value) {
            this.nationalityValue = value;
            this.showNationality = false;
            if(value == '其他'){
                this.nationalitySelectValue = '';
                this.showNationalityOther = true;
            } else{
                this.showNationalityOther = false;
                this.nationalitySelectValue = '中国';
            }
        },
        onConfirmNation(value) {
            this.nationValue = value;
            this.showNation = false;
        },
        onCancelPrivilege() {
            this.showPrivilege = false;
        },
        onHandleChange() {
            this.btnDisabled = false;
        },
        getCardInfo(idCard){
            let myDate = new Date();
            let month = myDate.getMonth() + 1;
            let day = myDate.getDate();
            let age = 0;

            if (idCard.length === 18) {
            age = myDate.getFullYear() - idCard.substring(6, 10) - 1;
            if (idCard.substring(10, 12) < month || idCard.substring(10, 12) === month && idCard.substring(12, 14) <= day)
                age++;

            }
            return {
                age
            }
        },
        goPage() {
             let params = {
                personName: this.personName,
                privielgeImgUrl: this.privielgeImgUrl,
                privilegeName: this.privilegeName,
                privilegeId: this.privilegeId,
                height: this.height,
                weight: this.weight,
                credentialsNumber: this.credentialsNumber,
                credentialsType: 1,
                applyDate: this.applyDate,
                applyDesc: this.applyDesc,
                phone: this.personPhone,
                regionCode: this.regionCode,
                caseList: this.caseListArray,
                diagnosticReport: this.diagnosticReportArray,
                otherList: this.otherListArray,
                applyDateSlot: this.docDateSlot,
                hospitalName: this.hospitalValue,
                nationality: this.nationalitySelectValue,
                nation: this.nationValue,
                guardianName: this.guardianName,
                guardianCardNumber: this.guardianCardNumber
            }
            if (this.personId) {
                Object.assign(params, {
                    personId: this.personId
                })
            }
            
            if(this.getCardInfo(this.credentialsNumber).age < 18){
                if (!this.guardianName) {
                    Toast('请输入监护人姓名');
                    return false;
                }
                if (!this.guardianCardNumber) {
                    Toast('请输入监护人身份证');
                    return false;
                }
            }
            if (!this.applyDate) {
                Toast('请选择预约时间');
                return false;
            }
            if(!this.nationalitySelectValue) {
                Toast('请填写国籍');
                return false;
            }
           

            if (!this.docDateSlot) {
                Toast('请选择预约时间段');
                return false;
            }
                localStorage.setItem('info', JSON.stringify(params));
                this.$router.push('/progressStep3Base');
        }
    }
}
</script>
<style scoped lang="less">
.content-radio {
    max-height: 400px;
    overflow-y: auto;
    .van-cell__title {
        text-align: center;
    }
    .img-icon {
        width: 20px;
    }
}
.padding-confirm {
    padding: 10px 20px;
}
.desc {
    letter-spacing: 1px;
}
.personal-height {
    padding: 0;
}
.progress-page {
    padding: 0 20px 70px;
    .minipro {
        display: flex;
        margin: 20px auto;
    }
    .stepList{
        flex:1;
        position:relative;

        .num {
            width: 30px;
            height: 30px;
            font-size: 16px;
            font-weight:500;
            text-align:center;
            line-height: 30px;
            margin:0 auto;
            background:#fff;
            border-radius:50%;
            color:#ccc;
            border:1px solid #ccc;
            &.active_num{
                background:#005dcc;
                border-radius:50%;
                color:#fff;
            }
        }
        .active_num{
            background:#005dcc;
            border-radius:50%;
            color:#fff;
        }
        .name{
            color:#ccc;
            font-size: 12px;
            font-weight: 500;
            margin-top: 7px;
            text-align: center;
            line-height: 18px;
            &.active_name {
                color:#005dcc;
            }
        }
        .img{
            width: 12px;
            position:absolute;
            right:-6px;
            top: 10px;
        }
    }
    &_footer {
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: #fff;
        z-index: 9;
        font-size: 14px;
        .explian {
            justify-content: space-between;
            height: 60px;
            line-height: 60px;
            padding: 0 20px;
        }
        .btn-options {
            padding: 10px 20px;
            border-top: 1px solid #f4f4f4;
        }
    }
}

.pd-left {
    padding: 10px 16px;
    border-bottom: 1px solid rgb(244, 244, 244);
   
}
.pd-none {
    .pd-left {
        border-bottom: none;
        padding-bottom: 0;
    }
}
.error-tips {
    position: relative;
    font-size: 14px;
    color: #646566;
    padding: 10px 16px;
    // padding: 0.26667rem 0.42667rem;
    &.tips {
        &::before {
            content: '*';
            position: absolute;
            left: 8px;
            top: 15px;
            color: #ee0a24;
            font-size: 0.37333rem;
        }
    }
    
}
.idcard-demo {
    .case-content {
        padding: 0 20px;
        .case {
            position: relative;
            width: 83px;
            height: 83px;
            margin-right: 5px;
            border-radius: 5px;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
            }
            p {
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                right: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                background: rgba(0, 0, 0, .7);
                span {
                    font-size: 14px;
                    color: #fff;
                }
            }
        }
    }
}
.box {
    &-select {
        border: 1px solid #005dcc;
        border-radius: 8px;
        padding: 10px 15px;
        margin: 20px 10px 10px;
        color: #323233;
        font-size: 16px;
    }
    &-explain {
        color: #005dcc;
        padding: 0 10px;
        font-size: 12px;
    }
}
</style>