import { render, staticRenderFns } from "./huli.vue?vue&type=template&id=330db66a&scoped=true&"
import script from "./huli.vue?vue&type=script&lang=js&"
export * from "./huli.vue?vue&type=script&lang=js&"
import style0 from "./huli.vue?vue&type=style&index=0&id=330db66a&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "330db66a",
  null
  
)

export default component.exports