<template>
    <div class="progress-page">
        <div class="rightDetail-step">
            <div class="reserve_box">
                <div class="minipro">
                    <div class="stepList">
                        <p class="num active_num">1</p>
                        <p class="name active_name">预约告知</p>
                        <img src="@/assets/progress.png" class="img">
                    </div>
                    <div class="stepList">
                        <p class="num">2</p>
                        <p class="name">预约申请</p>
                        <img src="@/assets/progress1.png" class="img">
                    </div>
                    <div class="stepList">
                        <p class="num">3</p>
                        <p class="name">预约安排</p>
                        <img src="@/assets/progress1.png" class="img">
                    </div>
                    <div class="stepList">
                        <p class="num">4</p>
                        <p class="name">预约完成</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="pre_box" v-if="applyUrl">
            <img :src="applyUrl" />
        </div>
        <!-- <div class="pre_box">
            <p class="title">本服务适用<span>淋巴瘤疾病</span>,请确认病症是否符合 </p>
            <table>
                <thead>
                    <tr>
                        <th>商品名</th>
                        <th>通用名</th>
                        <th>生产企业</th>
                        <th>适用疾病类型</th>
                        <th>限定使用范围</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>奕凯达</td>
                        <td>阿基仑赛注射液</td>
                        <td>复星凯特</td>
                        <td>淋巴瘤</td>
                        <td>用于治疗既往接受二线或以上系统性治疗后复发或难治性大B细胞淋巴瘤成人患者，包括弥漫性大B细胞淋巴瘤非特指型（DLBCL，NOS），原发纵隔大B细胞淋巴瘤（PMBCL）、高级别B细胞淋巴瘤（HGBL）和滤泡性淋巴瘤转化的弥漫性大B细胞淋巴瘤。</td>
                    </tr>
                    <tr>
                        <td>倍诺达</td>
                        <td>瑞基奥仑赛注射液</td>
                        <td>药明巨诺</td>
                        <td>淋巴瘤</td>
                        <td>本品用于治疗经过二线或以上系统性治疗后成人患者的复发或难治性大B细胞淋巴瘤，包括弥漫性大B细胞淋巴瘤非特指型、滤泡性淋巴瘤转化的弥漫性大B细胞淋巴瘤、3b级滤泡性淋巴瘤、原发纵隔大B细胞淋巴瘤、高级别B细胞淋巴瘤伴MYC和BCL-2和/或BCL-6重排（双打击/三打击淋巴瘤）</td>
                    </tr>
                </tbody>
            </table>
        </div> -->
        <div class="progress-page_footer fixed">
            <div class="flex explian">
                <span>请确认是否有以上病症</span>
                <div class="flex flex-column-center">
                    <van-radio-group
                        v-model="radioValue"
                        direction="horizontal"
                        @change="onHandleChange"
                    >
                        <van-radio name="1">有</van-radio>
                        <van-radio name="2">没有</van-radio>
                    </van-radio-group>
                </div>
            </div>
            <div class="btn-options">
                <van-button type="info" :disabled="btnDisabled" round block @click="goPage">下一步</van-button>
            </div>
        </div>
    </div>
</template>
<script>
import { Dialog } from 'vant';
export default {
    data() {
        return {
            radioValue: '',
            btnDisabled: true,
            applyUrl: ''
        }
    },
    created() {
        this.applyUrl = localStorage.getItem('applyUrl');

    },
    methods: {
        onHandleChange() {
            this.btnDisabled = false;
        },
        goPage() {
            if (this.radioValue == '2') {
                Dialog.alert({
                    title: '温馨提示',
                    message: '非常抱歉，您暂不符合预约条件，请查看其他权益。',
                    theme: 'round-button',
                    confirmButtonColor: '#1989fa'
                }).then(() => {
                // on close
                });
            } else {
                this.$router.push('/progressStep2');
            }
        }
    }
}
</script>
<style scoped lang="less">
.progress-page {
    padding: 0 20px 150px;
    .minipro {
        display: flex;
        margin: 20px auto;
    }
    .stepList{
        flex:1;
        position:relative;

        .num {
            width: 30px;
            height: 30px;
            font-size: 16px;
            font-weight:500;
            text-align:center;
            line-height: 30px;
            margin:0 auto;
            background:#fff;
            border-radius:50%;
            color:#ccc;
            border:1px solid #ccc;
            &.active_num{
                background:#005dcc;
                border-radius:50%;
                color:#fff;
            }
        }
        .active_num{
            background:#005dcc;
            border-radius:50%;
            color:#fff;
        }
        .name{
            color:#ccc;
            font-size: 12px;
            font-weight: 500;
            margin-top: 7px;
            text-align: center;
            line-height: 18px;
            &.active_name {
                color:#005dcc;
            }
        }
        .img{
            width: 12px;
            position:absolute;
            right:-6px;
            top: 10px;
        }
    }
    .pre_box {
        margin-top: 20px;
        padding: 10px 20px;
        border-radius: 10px;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .2);
        img {
            width: 100%;
        }
        // .title {
        //     font-size: 14px;
        //     font-weight: 500;
        //     line-height: 18px;
        //     color: #005dcc;
        //     margin: 10px 0 20px;
        // }
        // table {
        //     border-collapse: collapse;
        //     tr {
        //         td, th {
        //             border: 1px solid rgba(0, 0, 0, .8);
        //             border-collapse: collapse;
        //             font-size: 14px;
        //             color: #333;
        //             text-align: center;
        //         }
        //     }
        // }
    }
    &_footer {
        bottom: 0;
        left: 0;
        width: 100%;
        height: 140px;
        background-color: #fff;
        z-index: 9;
        font-size: 14px;
        .explian {
            justify-content: space-between;
            height: 60px;
            line-height: 60px;
            padding: 0 20px;
        }
        .btn-options {
            padding: 10px 20px;
            border-top: 1px solid #f4f4f4;
        }
    }
}
</style>