<template>
    <div class="progress-page">
        <div class="rightDetail-step">
            <div class="reserve_box">
                <div class="minipro">
                    <div class="stepList">
                        <p class="num active_num">1</p>
                        <p class="name active_name">预约告知</p>
                        <img src="@/assets/progress.png" class="img">
                    </div>
                    <div class="stepList">
                        <p class="num active_num">2</p>
                        <p class="name active_name">预约申请</p>
                        <img src="@/assets/progress1.png" class="img">
                    </div>
                    <div class="stepList">
                        <p class="num active_num">3</p>
                        <p class="name active_name">预约安排</p>
                        <img src="@/assets/progress1.png" class="img">
                    </div>
                    <div class="stepList">
                        <p class="num">4</p>
                        <p class="name">预约完成</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="content">
            <div class="box margin-bottom-sm">
                <h4 class="box-title">
                    <div class="box-title-con">
                        <i style="background: rgb(0, 93, 204);"></i>
                        <span>预约信息</span>
                    </div>
                </h4>
                <div class="info-list">
                    <dl>
                        <dt><img :src="info.privielgeImgUrl" /></dt>
                        <dd>
                            <p class="title">{{ info.privilegeName }}</p>
                            <p>预约人： {{ info.personName }}</p>
                            <p>手机号：{{ info.phone }}</p>
                            <p>预约时间 ：{{ info.applyDate }}{{ info.applyDateSlot }} </p>
                            <p>预约医院：{{info.hospitalName}}</p>
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
        <div class="progress-page_footer fixed">
            <div class="btn-options">
                <van-button type="info" round block @click="goPage">下一步</van-button>
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment';
moment().format();
import { onApplyPrivilege } from '@/api';
import { Dialog } from 'vant';
export default {
    data() {
        return {
            info: null,
            moment,
        }
    },
    created() {
        this.info = JSON.parse(localStorage.getItem('info'));
        console.log('this.info', this.info);
    },
    methods: {
       
        async goPage() {
            try {
                const res = await onApplyPrivilege(this.info);
                console.log('res', res);
                if (res.code == 200) {
                    this.$router.push(`/progressStep4?id=${res.data}`);
                } else {
                    this.$toast(res.msg);
                }
            } catch(err) {
                console.log('err', err);
            }
        }
    }
}
</script>
<style scoped lang="less">
.progress-page {
    padding: 0 20px 150px;
    .minipro {
        display: flex;
        margin: 20px auto;
    }
    .stepList{
        flex:1;
        position:relative;

        .num {
            width: 30px;
            height: 30px;
            font-size: 16px;
            font-weight:500;
            text-align:center;
            line-height: 30px;
            margin:0 auto;
            background:#fff;
            border-radius:50%;
            color:#ccc;
            border:1px solid #ccc;
            &.active_num{
                background:#005dcc;
                border-radius:50%;
                color:#fff;
            }
        }
        .active_num{
            background:#005dcc;
            border-radius:50%;
            color:#fff;
        }
        .name{
            color:#ccc;
            font-size: 12px;
            font-weight: 500;
            margin-top: 7px;
            text-align: center;
            line-height: 18px;
            &.active_name {
                color:#005dcc;
            }
        }
        .img{
            width: 12px;
            position:absolute;
            right:-6px;
            top: 10px;
        }
    }
    .content {
        .info-list {
            dl {
                padding: 10px;
                display: flex;
                dt {
                    img {
                        border-radius: 5px;
                        width: 60px;
                        height: 60px;
                        background: #000;
                    }
                }
                dd {
                    padding: 0 20px;
                    p {
                        color: #666;
                        font-size: 12px;
                        margin-bottom: 10px;
                        &.title {
                            font-size: 14px;
                            font-weight: 800;
                            color: #333;
                        }
                    }
                }
            }
        }
    }
    &_footer {
        bottom: 0;
        left: 0;
        width: 100%;
        height: 140px;
        background-color: #fff;
        z-index: 9;
        font-size: 14px;
        .explian {
            justify-content: space-between;
            height: 60px;
            line-height: 60px;
            padding: 0 20px;
        }
        .btn-options {
            padding: 10px 20px;
            border-top: 1px solid #f4f4f4;
        }
    }
}
</style>